export type ListEnumResponseModel = ListEnumModel[];

export interface ListEnumModel {
  Name: string;
  List: EnumModel[];
}

export interface EnumModel {
  ID: number;
  Text: string;
}
export enum EnumNames {
  CobrancaKit = "CobrancaKit",
  EquipamentoStatus = "EquipamentoStatus",
  IndiceReajuste = "IndiceReajuste",
  MotivoDesinstalacao = "MotivoDesinstalacao",
  TipoEquipamento = "TipoEquipamento",
  TipoKit = "TipoKit",
}
