import { ModeloContainer, ModeloHeader } from "./style";
import { Heading3 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect, useState } from "react";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";
import ServicoList from "./components/servico_list";
import SkeletonServicoList from "./components/servico_skeleton";
import ServicoErrorContent from "./components/servico_list_error";
import ServicoFilter from "./components/servico_filter";
import ModalBilling from "components/modal";
import ModalNewServico from "./page/NewModelo";
import useModelo from "hooks/useModelo";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const ModeloPage: React.FC = () => {
  const { getListModelos, isModeloError } = useModelo();
  const [openNewModal, setOpenNewModal] = useState<boolean>(false);
  const modelo = useSelector((state: RootState) => state.modelo);
  useEffect(() => {
    getListModelos();
  }, []);
  return (
    <Scaffold>
      <ModeloContainer>
        <InsideMenu title={"Modelo"} icon={"ad"}>
          <InsideMenuButton path={"/cadastro/kit"} name={"Kit"} icon={"home_repair_service"} />
          <InsideMenuButton path={"/cadastro/servico"} name={"Serviço"} icon={"handyman"} />{" "}
          <InsideMenuButton path={"/cadastro/modelo"} name={"Modelo"} icon={"ad"} />
        </InsideMenu>
        <div className="main_content">
          <ModeloHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Modelos
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>
                Gestão de todos os servicos na plataforma.
              </Body2>
            </div>

            <PrimaryButton
              label={"Novo modelo"}
              leftIcon={"add"}
              isSmall
              onTap={() => setOpenNewModal(true)}
            />
          </ModeloHeader>
          {isModeloError && <ServicoErrorContent retry={() => getListModelos()} />}

          {!isModeloError && <ServicoFilter />}

          {!isModeloError && (modelo.loading ? <SkeletonServicoList /> : <ServicoList />)}
        </div>
      </ModeloContainer>
      <ModalBilling modalIsOpen={openNewModal} width="450px" height="400px">
        <ModalNewServico
          onClose={() => {
            setOpenNewModal(false);
          }}
        />
      </ModalBilling>
    </Scaffold>
  );
};

export default ModeloPage;
