import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import billing_theme from "style/theme_colors";
import { NewServicoContent, NewServicoDetails } from "./style";
import BillingTextInput from "components/input/text_input";
import BillingTextArea from "components/input/text_area";
import useNewServico from "hooks/useNewServico";
import LineSkeleton from "components/skeleton/line_skeleton";

const ModalNewServico: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [nome, setNome] = useState<string>();
  const [descricao, setDescricao] = useState<string>();
  const { addNewServico, isLoadingServico } = useNewServico();

  return (
    <NewServicoContent>
      <NewServicoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Nome:
        </Body2>
        {isLoadingServico ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingTextInput
            placeholder="Qual o nome do produto?"
            change={value => {
              setNome(value);
            }}
          />
        )}
      </NewServicoDetails>
      <NewServicoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Quantidade:
        </Body2>

        {isLoadingServico ? (
          <LineSkeleton width={"100%"} height={100} />
        ) : (
          <BillingTextArea
            placeholder="De uma descrição ao serviço!"
            change={value => {
              setDescricao(value);
            }}
            lines={3}
          />
        )}
      </NewServicoDetails>

      <div className="button">
        <TertiaryButton label={"Cancelar"} onTap={onClose} isSmall />
        <PrimaryButton
          disable={nome == undefined || isLoadingServico}
          label={"Salvar"}
          onTap={async () => {
            await addNewServico(nome!, descricao);
            onClose();
          }}
          isSmall
        />
      </div>
    </NewServicoContent>
  );
};

export default ModalNewServico;
