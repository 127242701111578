import { ServicoHttp } from "gateways/repo/servico_http_client";
import { useState } from "react";
import { setServicoInfo } from "store/servico/view_servico_slice";
import { useAppDispatch } from "store/store";
import useServico from "./useServico";

const useViewServico = () => {
  const http = new ServicoHttp();
  const dispatch = useAppDispatch();
  const [isLoadingServico, setLoading] = useState(true);
  const [isServicoError, setError] = useState(false);
  const { getServicos } = useServico();

  const getServico = async (id: string) => {
    setLoading(true);
    setError(false);
    try {
      const data = await http.getServico(id);
      dispatch(setServicoInfo(data.Objeto));
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const updateServico = async (id: number, nome: string, descricao?: string) => {
    setLoading(true);
    setError(false);
    try {
      await http.updateServico({ ID: id, Nome: nome, Descricao: descricao });
      getServicos();
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    getServico,
    updateServico,
    isLoadingServico,
    isServicoError,
  };
};
export default useViewServico;
