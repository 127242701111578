import LineSkeleton from "components/skeleton/line_skeleton";
import {
  ContratoListButton,
  ContratoListContent,
  ContratoListHeader,
  SkeletonContratoListItensContainer,
} from "./style";
import React from "react";
const SkeletonContratoList: React.FC<{}> = () => {
  const array = ["", "", "", "", "", "", "", "", ""];

  return (
    <ContratoListContent>
      <ContratoListHeader>
        {array.map((el, index) => (
          <SkeletonListButton key={index} />
        ))}
      </ContratoListHeader>
      {array.map((el, index) => (
        <SkeletonContratoListItensContainer key={index}>
          <LineSkeleton height={58} width={"100%"} />
        </SkeletonContratoListItensContainer>
      ))}
    </ContratoListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <ContratoListButton>
      <LineSkeleton height={30} width={90} />
    </ContratoListButton>
  );
};

export default SkeletonContratoList;
