import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModeloResponseList } from "shared/models/modelo_list_response.model";

export interface ModeloListState {
  list?: ModeloResponseList[];
  filter?: ModeloResponseList[];
  loading?: boolean;
}

const initialState: ModeloListState = {
  list: [],
  filter: [],
};

const modelo_slice = createSlice({
  name: "modelo",
  initialState,
  reducers: {
    setModeloList(state, action: PayloadAction<ModeloResponseList[]>) {
      state.list = action.payload;
    },
    setModeloFilter(state, action: PayloadAction<ModeloResponseList[]>) {
      state.filter = action.payload;
    },
    setLoadingModelo(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setModeloList, setModeloFilter, setLoadingModelo } = modelo_slice.actions;
export default modelo_slice.reducer;
