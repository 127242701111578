import { KitPrincipalContent, NovoContratoInputLabel } from "./style";
import React from "react";
import { Heading5, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingSelectInput from "components/input/select";
import {
  setDesinstalacao,
  setDuracaoCarencia,
  setInstalacao,
  setKitPrincipal,
  setMensalidade,
  setQuantidadeKitPrincipal,
  setTransferencia,
} from "store/contrato/new_contrato_slice";
import BillingNumberInput from "components/input/number_input";

const NovoCotratoKitPrincipal: React.FC = () => {
  const state = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  return (
    <KitPrincipalContent>
      <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A400}>
        Kit principal
      </Heading5>

      <div className="select_kit_principal">
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Kit
          </Heading6>
          <BillingSelectInput
            onChange={(val: any) => {
              val != null ? dispatch(setKitPrincipal(val?.value!)) : dispatch(setKitPrincipal(""));
            }}
            options={state.kit.options}
            placeholder={"Selecione seu kit Principal"}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Quantidade
          </Heading6>
          <BillingNumberInput
            width={300}
            placeholder="Qual a quantidade?"
            onNumberFormat={val => {
              dispatch(setQuantidadeKitPrincipal(val.number));
            }}
          />
        </NovoContratoInputLabel>
      </div>
      <div className="valores">
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Mensalidade Total
          </Heading6>
          <BillingNumberInput
            format="currency"
            placeholder="Qual é a mensalidade total"
            onNumberFormat={val => {
              dispatch(setMensalidade(val.number));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Instalação
          </Heading6>
          <BillingNumberInput
            format="currency"
            placeholder="Qual o valor da instalação?"
            onNumberFormat={val => {
              dispatch(setInstalacao(val.number));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Desinstalação
          </Heading6>
          <BillingNumberInput
            format="currency"
            placeholder="Qual o valor da desinstalação?"
            onNumberFormat={val => {
              dispatch(setDesinstalacao(val.number));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Transfêrencia
          </Heading6>
          <BillingNumberInput
            format="currency"
            placeholder="Qual o valor da transfêrencia"
            onNumberFormat={val => {
              dispatch(setTransferencia(val.number));
            }}
          />
        </NovoContratoInputLabel>
      </div>
    </KitPrincipalContent>
  );
};
export default NovoCotratoKitPrincipal;
