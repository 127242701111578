import {
  SkeletonNovoContratoConfiguracao,
  SkeletonNovoContratoInputLabel,
  SkeletonNovoContratoKit,
  SkeletonNovoContratoPrazosDesconto,
  SkeletonNovoContratosForm,
} from "./style";
import React from "react";
import LineSkeleton from "components/skeleton/line_skeleton";

const NovoContratoSkeleton: React.FC = () => {
  return (
    <>
      <SkeletonNovoContratosForm>
        <div className="contrantes_contratoInicial">
          <SkeletonNovoContratoInputLabel>
            <LineSkeleton height={20} width={120} />
            <LineSkeleton height={41} width={"100%"} />
          </SkeletonNovoContratoInputLabel>
          <SkeletonNovoContratoInputLabel>
            <LineSkeleton height={20} width={120} />
            <LineSkeleton height={41} width={"100%"} />
          </SkeletonNovoContratoInputLabel>
        </div>
        <SkeletonNovoContratoInputLabel>
          <LineSkeleton height={20} width={120} />
          <LineSkeleton height={41} width={"100%"} />
        </SkeletonNovoContratoInputLabel>
      </SkeletonNovoContratosForm>
      <SkeletonNovoContratoPrazosDesconto>
        <div className="prazo">
          <LineSkeleton height={30} width={120} />
        </div>
        <div className="desconto">
          <LineSkeleton height={30} width={120} />
        </div>
        <div className="prazo">
          <SkeletonNovoContratoInputLabel>
            <LineSkeleton height={20} width={120} />
            <LineSkeleton height={41} width={"100%"} />
          </SkeletonNovoContratoInputLabel>
          <SkeletonNovoContratoInputLabel>
            <LineSkeleton height={20} width={120} />
            <LineSkeleton height={41} width={"100%"} />
          </SkeletonNovoContratoInputLabel>
          <SkeletonNovoContratoInputLabel>
            <LineSkeleton height={20} width={120} />
            <LineSkeleton height={41} width={"100%"} />
          </SkeletonNovoContratoInputLabel>
        </div>

        <div className="desconto">
          <SkeletonNovoContratoInputLabel>
            <LineSkeleton height={20} width={120} />
            <LineSkeleton height={41} width={"100%"} />
          </SkeletonNovoContratoInputLabel>
          <SkeletonNovoContratoInputLabel>
            <LineSkeleton height={20} width={120} />
            <LineSkeleton height={41} width={"100%"} />
          </SkeletonNovoContratoInputLabel>
        </div>
      </SkeletonNovoContratoPrazosDesconto>
      <SkeletonNovoContratoConfiguracao>
        <LineSkeleton height={30} width={120} />

        <div className="configInput">
          <LineSkeleton height={41} width={235} />
          <LineSkeleton height={21} width={400} />
        </div>

        <div className="prorata">
          <LineSkeleton height={17} width={200} />
          <LineSkeleton height={17} width={200} />
        </div>
      </SkeletonNovoContratoConfiguracao>
      <SkeletonNovoContratoKit>
        <div className="header">
          <LineSkeleton height={30} width={120} />
        </div>
        <LineSkeleton height={100} width={"100%"} />

        <div className="buttonContent">
          <LineSkeleton height={41} width={200} />
        </div>
      </SkeletonNovoContratoKit>
      <SkeletonNovoContratoKit>
        <div className="header">
          <LineSkeleton height={30} width={120} />
        </div>
        <LineSkeleton height={100} width={"100%"} />

        <div className="buttonContent">
          <LineSkeleton height={41} width={200} />
        </div>
      </SkeletonNovoContratoKit>
    </>
  );
};
export default NovoContratoSkeleton;
