import { BillingHttp } from "gateways/billing_http";
import { OptionsResponseModel } from "shared/models/default_models.model";
import { ServicoResponseModel } from "shared/models/servico_response.model";
import { ViewServicoResponse } from "shared/models/view_servico_response.model";

export class ServicoHttp {
  private http = new BillingHttp();

  getServicosList = async () => {
    const reponse = await this.http.get<ServicoResponseModel>("Servico/List");

    return reponse.data;
  };

  getServicosOptions = async () => {
    const response = await this.http.get<OptionsResponseModel>("Servico/ListOptions");
    return response.data;
  };

  getServico = async (id: string) => {
    const response = await this.http.get<ViewServicoResponse>("Servico/Get?id=" + id);
    return response.data;
  };

  addNewServico = async (data: AddNewServicoPost) => {
    const response = await this.http.post("Servico/Insert", { data: data });
    return response.data;
  };
  updateServico = async (data: AddNewServicoPost) => {
    const response = await this.http.put("Servico/Update", { data: data });
    return response.data;
  };
  deleteServico = async (id: number | string) => {
    const response = await this.http.delete("Servico/Delete?id=" + id);
    return response.data;
  };
}
export interface AddNewServicoPost {
  ID: number;
  Nome: string;
  Descricao?: string;
}
