import { KitContainer } from "./style";
import Scaffold from "components/scaffold";
import { useEffect } from "react";
import useViewKit from "hooks/useViewKit";
import KitViewMain from "./components/kit_view_main";
import SkeletonKitList from "./components/kit_list_skeleton";
import useBillingEnum from "hooks/useBillingEnum";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const ViewKitPage: React.FC = () => {
  const { getViewKit } = useViewKit();
  const { getEnums, isLoadingEnum } = useBillingEnum();
  const viewKit = useSelector((state: RootState) => state.view_kit);
  useEffect(() => {
    getViewKit();
    getEnums();
  }, []);

  return (
    <Scaffold>
      <KitContainer>{viewKit.loading ? <SkeletonKitList /> : <KitViewMain />}</KitContainer>
    </Scaffold>
  );
};

export default ViewKitPage;
