import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toastWarning } from "components/toast/billing_toast";
import {
  KitComplementar,
  RegaInstalacao,
  ServicoNewContrato,
} from "shared/models/contrato_post.model";

export interface NovoContratoSlice {
  contratante?: string;
  contratada?: string;
  kitPrincipal?: string;
  quantidade?: number;
  mensalidade?: number;
  instalacao?: number;
  desinstalacao?: number;
  transferencia?: number;
  duracao?: number;
  carencia?: number;
  fidelidade?: number;
  porcentagem?: number;
  meses?: number;
  indice?: number;
  valorReajustavel?: number;
  valorNaoReajustavel?: number;
  proRataInicio?: boolean;
  proRataDesinstalacao?: boolean;
  RegasInsta: RegaInstalacao[];
  kitsComplementares: KitComplementar[];
  servico: ServicoNewContrato[];
  pageIndex: number;
}

const initialState: NovoContratoSlice = {
  kitsComplementares: [],
  servico: [],
  RegasInsta: [],
  pageIndex: 0,
};

const new_contrato_slice = createSlice({
  name: "new_contrato",
  initialState,
  reducers: {
    resetNovoContratoState: () => initialState,
    setContratanteState(state, action: PayloadAction<string>) {
      state.contratante = action.payload;
    },

    setContratadaState(state, action: PayloadAction<string>) {
      state.contratada = action.payload;
    },
    setDuracaoState(state, action: PayloadAction<number>) {
      state.duracao = action.payload;
    },
    setCarenciaState(state, action: PayloadAction<number>) {
      state.carencia = action.payload;
    },
    setFidelidadeState(state, action: PayloadAction<number>) {
      state.fidelidade = action.payload;
    },
    setPorcentagemState(state, action: PayloadAction<number>) {
      state.porcentagem = action.payload;
    },
    setMesesState(state, action: PayloadAction<number>) {
      state.meses = action.payload;
    },
    setIndiceState(state, action: { payload: number | undefined; type: string }) {
      state.indice = action.payload;
    },
    addKitState(state, action: PayloadAction<KitComplementar>) {
      if (state.kitsComplementares.find(el => el.ID == action.payload.Kit_ID)) {
        toastWarning({ title: "Você já adicionou esse kit!" });
      } else {
        state.kitsComplementares.push(action.payload);
      }
    },
    addServicoState(state, action: PayloadAction<ServicoNewContrato>) {
      if (state.servico.find(el => el.Servico_ID == action.payload.Servico_ID)) {
        toastWarning({ title: "Você já adicionou esse serviço!" });
      } else {
        state.servico.push(action.payload);
      }
    },
    removeKitState(state, action: PayloadAction<KitComplementar>) {
      state.kitsComplementares = state.kitsComplementares.filter(
        el => action.payload.Kit_ID !== el.Kit_ID
      );
    },
    removeServicoState(state, action: PayloadAction<ServicoNewContrato>) {
      state.servico = state.servico.filter(el => action.payload.Servico_ID !== el.Servico_ID);
    },
    setKitPrincipal(state, action: PayloadAction<string>) {
      state.kitPrincipal = action.payload;
    },
    setQuantidadeKitPrincipal(state, action: PayloadAction<number>) {
      state.quantidade = action.payload;
    },
    setValorReajuste(state, action: PayloadAction<number>) {
      state.valorReajustavel = action.payload;
    },
    setValorNaoReajustavel(state, action: PayloadAction<number>) {
      state.valorNaoReajustavel = action.payload;
    },
    setProRataInstacao(state, action: PayloadAction<boolean>) {
      state.proRataInicio = action.payload;
    },
    setProRataDesinstacao(state, action: PayloadAction<boolean>) {
      state.proRataDesinstalacao = action.payload;
    },
    setInstalacao(state, action: PayloadAction<number>) {
      state.instalacao = action.payload;
    },
    setDesinstalacao(state, action: PayloadAction<number>) {
      state.desinstalacao = action.payload;
    },
    setTransferencia(state, action: PayloadAction<number>) {
      state.transferencia = action.payload;
    },
    setDuracaoCarencia(state, action: PayloadAction<number>) {
      state.carencia = action.payload;
    },
    setMensalidade(state, action: PayloadAction<number>) {
      state.mensalidade = action.payload;
    },
    setActualPage(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
  },
});

export const {
  setMensalidade,
  setTransferencia,
  setDuracaoCarencia,
  setQuantidadeKitPrincipal,
  setProRataInstacao,
  setProRataDesinstacao,
  setValorNaoReajustavel,
  setValorReajuste,
  setKitPrincipal,
  setDesinstalacao,
  setInstalacao,
  setContratanteState,
  removeServicoState,
  addKitState,
  addServicoState,
  removeKitState,
  setContratadaState,
  setCarenciaState,
  setFidelidadeState,
  setPorcentagemState,
  setMesesState,
  setIndiceState,
  setDuracaoState,
  resetNovoContratoState,
  setActualPage,
} = new_contrato_slice.actions;
export default new_contrato_slice.reducer;
