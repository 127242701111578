import React from "react";
import { WarningIconContent, WarningTextContent, WarningToastContainer } from "./style";
import { SymbolCodepoints } from "react-material-symbols";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body4 } from "components/text/body/style";

const WarningToastComp: React.FC<{
  icon?: SymbolCodepoints;
  title: string;
  message?: string;
}> = ({ icon, title, message }) => {
  return (
    <WarningToastContainer>
      <WarningIconContent>
        <BillingIconSharp
          icon={icon ?? "warning"}
          color={billing_theme.pallete.tertiary.A50}
          size={30}
        />
      </WarningIconContent>
      <WarningTextContent>
        <Heading5 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {title}
        </Heading5>
        {message && <Body4 color={billing_theme.pallete.tertiary.A400}>{message}</Body4>}
      </WarningTextContent>
    </WarningToastContainer>
  );
};

export default WarningToastComp;
