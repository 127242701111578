import React from "react";
import { Heading5, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { NovoContratoInputLabel, NovoContratoPrazosDesconto } from "./style";
import BillingNumberInput from "components/input/number_input";
import { useAppDispatch } from "store/store";
import {
  setCarenciaState,
  setDuracaoState,
  setFidelidadeState,
} from "store/contrato/new_contrato_slice";

const NovoContratoPrazosComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <NovoContratoPrazosDesconto>
      <div className="prazo">
        <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A600}>
          Prazos
        </Heading5>
      </div>

      <div className="prazo">
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Duração (Meses)
          </Heading6>
          <BillingNumberInput
            placeholder="Qual a duração?"
            onNumberFormat={val => {
              dispatch(setDuracaoState(val.number));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Carência (Dias)
          </Heading6>
          <BillingNumberInput
            placeholder="Qual a duração da Carência?"
            onNumberFormat={val => {
              dispatch(setCarenciaState(val.number));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Fidelidade (Meses)
          </Heading6>
          <BillingNumberInput
            placeholder="Qual a duração da fidelidade?"
            onNumberFormat={val => {
              dispatch(setFidelidadeState(val.number));
            }}
          />
        </NovoContratoInputLabel>
      </div>
    </NovoContratoPrazosDesconto>
  );
};
export default NovoContratoPrazosComponent;
