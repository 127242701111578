import { useEffect, useState } from "react";
import {
  ServicoListButton,
  ServicoListContent,
  ServicoListHeader,
  ServicoListIcon,
  ServicoListItems,
  ServicoListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import useServico from "hooks/useServico";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { ServicoModel } from "shared/models/servico_response.model";
import { toMoney } from "utils/numberUtils";
import ModalBilling from "components/modal";
import ModalViewServico from "view/Servico/page/ViewServico";
import ServicoDeleteContent from "../servico_delete_modal";

const ServicoList: React.FC<{}> = () => {
  const servico = useSelector((state: RootState) => state.servico);
  const {
    filterServico,
    filterServicoReverse,
    deleteServico,
    openDeleteModalServico,
    setDeleteModalServico,
    setDeleteIdServico,
    loadModal,
  } = useServico();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<ServicoModel[]>([]);
  const [filterState, setFilterState] = useState("normal");
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [deleteServicoName, setdeleteServicoName] = useState<string>();

  const selectFilter = (id: string, index: number) => {
    if (filter != id) setFilterState("normal");

    if (filterState == "normal") {
      filterServico(index);
      setFilterState("reverse");
    } else {
      filterServicoReverse(index);
      setFilterState("normal");
    }
    setFilter(id);
  };

  useEffect(() => {
    servico.filterList.length == 0 ? setData(servico.list) : setData(servico.filterList);
  });
  return (
    <ServicoListContent>
      <ServicoListHeader>
        <ListButton
          label="Nome"
          filterState={filterState}
          click={() => selectFilter("Nome", 1)}
          selectedFilter={filter}
        />
        <ListButton
          label="Preço base"
          filterState={filterState}
          click={() => selectFilter("Preço base", 2)}
          selectedFilter={filter}
        />

        <ListButton
          label="Opções"
          filterState={filterState}
          click={() => console.log("oi")}
          selectedFilter={"options"}
        />
      </ServicoListHeader>
      {data.map(item => (
        <ServicoListItensContainer>
          <ListItens label={item.Nome} />
          <ListItens label={toMoney(item.PrecoBase)} />

          <ServicoListIcon>
            <BillingIconRounded
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={() => {
                setSelectedId(item.ID);
                setOpenViewModal(true);
              }}
            />
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              onTap={() => {
                setDeleteIdServico(item.ID);
                setDeleteModalServico(true);
                setdeleteServicoName(item.Nome);
              }}
            />
          </ServicoListIcon>
        </ServicoListItensContainer>
      ))}
      <ModalBilling modalIsOpen={openDeleteModalServico!} width="500px" height="200px">
        <ServicoDeleteContent
          onClose={() => setDeleteModalServico(false)}
          onDelete={() => deleteServico()}
          onLoading={loadModal!}
          servico={deleteServicoName!}
        />
      </ModalBilling>
      <ModalBilling modalIsOpen={openViewModal} width="450px" height="300px">
        <ModalViewServico
          id={selectedId!}
          onClose={() => {
            setOpenViewModal(false);
          }}
        />
      </ModalBilling>
    </ServicoListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <ServicoListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </ServicoListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <ServicoListItems>
      <Body3 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body3>
    </ServicoListItems>
  );
};
export default ServicoList;
