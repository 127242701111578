import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const NovoContratoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const KitPrincipalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .select_kit_principal {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .valores {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
`;
