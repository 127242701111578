import React, { useState } from "react";
import { Heading5, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import BillingTextInput from "components/input/text_input";
import BillingCheckbox from "components/input/checkbox";
import { NovoContratoConfiguracao } from "./style";
import BillingNumberInput from "components/input/number_input";
import { NovoContratoInputLabel } from "../contratos/style";
import BillingSelectInput from "components/input/select";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import {
  setIndiceState,
  setProRataDesinstacao,
  setProRataInstacao,
  setValorNaoReajustavel,
  setValorReajuste,
} from "store/contrato/new_contrato_slice";

const NovoContratoConfiguracaoComp: React.FC = () => {
  const enumList = useSelector((state: RootState) => state.list_enum);
  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  const dispatch = useAppDispatch();

  const [naoReajustavel, setNaoReajustavel] = useState<number>(0);
  return (
    <NovoContratoConfiguracao>
      <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A600}>
        Configurações
      </Heading5>

      <div className="configInput">
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Indice Reajuste
          </Heading6>
          <BillingSelectInput
            onChange={val => {
              val !== null
                ? dispatch(setIndiceState(Number(val.value!)))
                : dispatch(setIndiceState());
            }}
            options={enumList.IndiceReajusteOptions!}
            placeholder={"Indice de reajuste"}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Valor reajustavel
          </Heading6>
          <BillingNumberInput
            format="currency"
            placeholder="Valor do reajsute"
            onNumberFormat={value => {
              dispatch(setValorReajuste(value.number));
              setNaoReajustavel(novo_contrato.mensalidade! - value.number);
              dispatch(setValorNaoReajustavel(novo_contrato.mensalidade! - value.number));
            }}
            error={novo_contrato.mensalidade! < novo_contrato.valorReajustavel!}
            errorMesage="O valor não deve ser maior que a mensalidade!"
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Valor não reajustavel
          </Heading6>
          <BillingNumberInput
            format="currency"
            disabled={true}
            placeholder="Valor não reajustavel"
            value={naoReajustavel}
            alterValue={naoReajustavel}
            error={novo_contrato.mensalidade! < novo_contrato.valorReajustavel!}
            errorMesage="O valor não pode ser negativo"
          />
        </NovoContratoInputLabel>
      </div>

      <div className="prorata">
        <BillingCheckbox
          label="Aplicar pro rata da instalação."
          change={value => {
            dispatch(setProRataInstacao(value!));
          }}
        />
        <BillingCheckbox
          label="Aplicar pro rata na desinstalação."
          change={value => {
            dispatch(setProRataDesinstacao(value!));
          }}
        />
      </div>
    </NovoContratoConfiguracao>
  );
};
export default NovoContratoConfiguracaoComp;
