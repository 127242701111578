import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const InfoContent = styled.div`
  display: flex;
`;

export const InfoButton = styled.div`
  height: 64px;
  display: flex;
  background-color: transparent;
  border: none;
  border-right: solid 0.5px
    ${billing_theme.isDark()
      ? billing_theme.light.primary?.A200
      : billing_theme.light.primary?.A300};
  padding: 0 16px;
  align-items: center;
  cursor: pointer;
`;

export const ContextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-width: 230px;
  justify-content: space-between;

  .image img {
    width: 32px;
  }
  .context {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
