import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const SkeletonNovoContratoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const SkeletonNovoContratosForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .contrantes_contratoInicial {
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 16px;
  }
`;

export const SkeletonNovoContratoPrazosDesconto = styled.div`
  display: grid;
  grid-template-columns: 65% 1fr;
  grid-template-rows: 27px 1fr;
  gap: 6px 12px;
  .prazo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }

  .desconto {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
`;

export const SkeletonNovoContratoConfiguracao = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .configInput {
    display: grid;
    grid-template-columns: 25% 1fr;
    align-items: center;
    gap: 8px;
  }

  .prorata {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const SkeletonNovoContratoKit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    text-align: center;
  }

  .no_content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: solid 0.5px ${billing_theme.pallete.primary.A100};
    background: ${billing_theme.pallete.primary.A50};
  }
  .buttonContent {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
