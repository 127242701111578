import { toastInfo, toastError } from "components/toast/billing_toast";
import { ServicoHttp } from "gateways/repo/servico_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ServicoModel } from "shared/models/servico_response.model";
import {
  setErrorServicos,
  setLoadingServicos,
  setServicoFilterList,
  setServicoList,
  setServicoOptions,
} from "store/servico/servico_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useServico = () => {
  const http = new ServicoHttp();
  const dispatch = useAppDispatch();
  const servico = useSelector((state: RootState) => state.servico);
  const [openDeleteModalServico, setDeleteModalServico] = useState<boolean>();
  const [deleteIdServico, setDeleteIdServico] = useState<string | number>();
  const [loadModal, setLoadingModal] = useState(false);

  const getServicos = async () => {
    dispatch(setLoadingServicos(true));
    dispatch(setErrorServicos(false));

    try {
      const data = await http.getServicosList();
      dispatch(setServicoList(data.Lista));
    } catch (e) {
      console.log(e);
      dispatch(setErrorServicos(true));
    } finally {
      dispatch(setLoadingServicos(false));
    }
  };
  const getServiocOptions = async () => {
    try {
      const data = await http.getServicosOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });
      console.log(data.Lista);
      dispatch(setServicoOptions(options));
    } catch (e) {
      console.log(e);
    }
  };

  const filterServico = (key: number) => {
    const filter: ServicoModel[] = [];

    servico.list.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => a.Nome.toLowerCase().localeCompare(b.Nome.toLowerCase()));

        break;

      default:
        filter.sort((a, b) => a.PrecoBase - b.PrecoBase);
    }

    dispatch(setServicoFilterList(filter));
  };

  const filterServicoReverse = (key: number) => {
    const filter: ServicoModel[] = [];

    servico.list.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => b.Nome.toLowerCase().localeCompare(a.Nome.toLowerCase()));
        break;

      default:
        filter.sort((a, b) => b.PrecoBase - a.PrecoBase);
    }

    dispatch(setServicoFilterList(filter));
  };

  const filterByName = (name: string) => {
    const filter: ServicoModel[] = [];

    dispatch(setServicoFilterList([]));
    servico.list.forEach(el => {
      if (el.Nome.toLowerCase().includes(name.toLowerCase())) {
        filter.push(el);
      }
    });

    dispatch(setServicoFilterList(filter));
  };
  const deleteServico = async () => {
    setLoadingModal(true);
    try {
      await http.deleteServico(deleteIdServico!);
      toastInfo({ title: "Servico excluido!" });
      setDeleteModalServico(false);
      getServicos();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      setLoadingModal(false);
    }
  };
  return {
    getServicos,
    getServiocOptions,
    filterServico,
    filterServicoReverse,
    filterByName,
    deleteServico,
    openDeleteModalServico,
    setDeleteModalServico,
    setDeleteIdServico,
    loadModal,
  };
};
export default useServico;
