import { ModeloHttp, PostModeloModel } from "gateways/repo/modelo_http_client";
import { useState } from "react";
import { setModelo } from "store/modelo/view_modelo_slice";
import { useAppDispatch } from "store/store";
import useModelo from "./useModelo";

const useViewModelo = () => {
  const http = new ModeloHttp();
  const dispatch = useAppDispatch();
  const [isLoadingModelo, setLoading] = useState(true);
  const [isModeloError, setError] = useState(false);
  const { getListModelos } = useModelo();

  const getModelo = async (id: number) => {
    setLoading(true);
    setError(false);
    try {
      const data = await http.getModelo(id);
      dispatch(setModelo(data.Objeto));
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const updateModelo = async (data: PostModeloModel) => {
    setLoading(true);
    setError(false);
    try {
      await http.updateNewModelo(data);
      getListModelos();
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return {
    getModelo,
    isLoadingModelo,
    isModeloError,
    updateModelo,
  };
};
export default useViewModelo;
