import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import BillingNumberInput from "components/input/number_input";
import BillingSelectInput from "components/input/select";
import { Body2 } from "components/text/body/style";
import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { Acessorio } from "shared/models/kit_view_response.model";
import { RootState } from "store/store";
import billing_theme from "style/theme_colors";
import { NewKitDetails } from "../../new_kit_main/style";
import { ModalContent } from "../style";

const ModalKitItens: React.FC<{
  onClose: () => void;
  change: (id_modelo?: number, quantidade?: number) => void;
  item: Acessorio;
}> = ({ onClose, change, item }) => {
  const newKit = useSelector((state: RootState) => state.new_kit);
  const [quantidade, setQuantidade] = useState<number>();
  const [id_modelo, setIdModelo] = useState<number>();

  const getOptionsModelo = () => {
    const options: OptionsModel[] = [];

    newKit.modelo.forEach(el => {
      options.push({ value: el.ID.toString(), label: el.Text });
    });
    return options;
  };

  const modeloValue = () => {
    for (let index = 0; index < getOptionsModelo().length; index++) {
      if (getOptionsModelo()[index].value === item.Modelo_ID.toString()) {
        return getOptionsModelo()[index];
      }
    }
  };

  return (
    <ModalContent>
      <NewKitDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Modelo:
        </Body2>
        <BillingSelectInput
          placeholder="Selecione um modelo:"
          options={getOptionsModelo()}
          defaultInputValue={modeloValue()}
          onChange={(val: any) => {
            val != null ? setIdModelo(parseInt(val.value)) : setIdModelo(undefined);
          }}
        />
      </NewKitDetails>
      <NewKitDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Quantidade:
        </Body2>
        <BillingNumberInput
          value={item.Quantidade}
          onNumberFormat={num => setQuantidade(num.number)}
        />
      </NewKitDetails>

      <div className="button">
        <TertiaryButton label={"Cancelar"} onTap={onClose} isSmall />
        <PrimaryButton
          label={"Salvar"}
          onTap={() => {
            change(id_modelo ?? parseInt(modeloValue()?.value!), quantidade ?? item.Quantidade);
          }}
          isSmall
        />
      </div>
    </ModalContent>
  );
};

export default ModalKitItens;
