import React from "react";
import "react-material-symbols/outlined";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import billing_theme from "style/theme_colors";
const LineSkeleton: React.FC<{
  width: number | string;
  height: number;
}> = ({ width, height }) => {
  return (
    <Skeleton
      height={height}
      width={width}
      baseColor={billing_theme.pallete.tertiary.A100}
      highlightColor={billing_theme.pallete.tertiary.A50}
    />
  );
};

export default LineSkeleton;
