import React, { useState } from "react";
import { CheckboxContent } from "./style";
import billing_theme from "style/theme_colors";
import { Heading6 } from "components/text/heading/style";
import { Caution1 } from "components/text/body/style";

const BillingCheckbox: React.FC<{
  label?: string;
  change: (value?: boolean) => void;
}> = ({ label, change }) => {
  const [isChecked, setChecked] = useState(false);
  return (
    <CheckboxContent>
      <input
        type="checkbox"
        onChange={ev => {
          setChecked(!isChecked);
          change(!isChecked);
        }}
      ></input>
      <span className={(isChecked ? "checked" : "") + " checkmark"}></span>
      <Caution1 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
        {label}
      </Caution1>
    </CheckboxContent>
  );
};

export default BillingCheckbox;
