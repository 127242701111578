import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const MenuButton = styled.button<{
  selected: boolean;
}>`
  display: flex;
  height: 64px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  align-self: stretch;
  width: 88px;
  color: ${props =>
    props.selected ? billing_theme.pallete.primary?.A50 : billing_theme.pallete.tertiary?.A200};
  border: none;
  border-bottom: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  background: ${props =>
    props.selected
      ? billing_theme.isDark()
        ? billing_theme.light.primary?.A300
        : billing_theme.light.primary?.A400
      : "transparent"};
  cursor: pointer;

  .menu-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;
