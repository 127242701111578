import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const SideBarOverview = styled.div<{ open: boolean }>`
  position: fixed;

  z-index: 999;
  height: ${props => (props.open ? "100vh" : "0vh")};
  width: ${props => (props.open ? "100vw" : "0vw")};
  background-color: transparent;
`;

export const SidebarContent = styled.div`
  box-shadow:
    inset 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  float: right;
  position: relative;
  height: 100vh;
  width: 45vw;
  max-width: 1000px;
  background: ${billing_theme.pallete.tertiary.A25};
  overflow-y: scroll;
  transition: all 0.7s ease;
`;
