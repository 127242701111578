import Scaffold from "components/scaffold";
import { useEffect } from "react";
import useViewFatura from "hooks/useViewFatura";
import FaturaViewMain from "./components/FaturaViewMain";
import ViewFaturaItensTable from "./components/ViewFaturaInstalacaoTable";
import LineSkeleton from "components/skeleton/line_skeleton";
import { LoadingComponent } from "./style";
import ViewFaturaError from "./components/VeiwFaturaError";

const ViewFaturaPage: React.FC = () => {
  const { getFaturaById, isLoading, isFaturaError } = useViewFatura();

  useEffect(() => {
    getFaturaById();
  }, []);
  return (
    <Scaffold>
      {!isLoading && !isFaturaError && <FaturaViewMain />}
      {isLoading && (
        <LoadingComponent>
          <LineSkeleton width={"100%"} height={600} />
        </LoadingComponent>
      )}
      {isFaturaError && (
        <ViewFaturaError
          retry={() => {
            getFaturaById();
          }}
        />
      )}
    </Scaffold>
  );
};

export default ViewFaturaPage;
