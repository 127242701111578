import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ContratoModelLit, ListaConfiguracaoModel } from "shared/models/contratos_response.model";

export interface ContratoSliceType {
  list: ContratoModelLit[];
  filterList: ContratoModelLit[];
  config: ListaConfiguracaoModel;
  options?: OptionsModel[];
  loading?: boolean;
}

const initialState: ContratoSliceType = {
  list: [],
  filterList: [],
  config: {
    TotalRegistros: 0,
    PaginaAtual: 0,
    RegistrosPorPagina: 0,
  },
};

const contrato_slice = createSlice({
  name: "contrato",
  initialState,
  reducers: {
    setContratosList(state, action) {
      state.list = action.payload;
    },
    setFilterList(state, action) {
      state.filterList = action.payload;
    },
    setConfigList(state, action) {
      state.list = action.payload;
    },
    setOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setLoadingContrato(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setContratosList, setConfigList, setFilterList, setOptions, setLoadingContrato } =
  contrato_slice.actions;
export default contrato_slice.reducer;
