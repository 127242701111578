import styled from "styled-components";

export const AvatarContent = styled.div<{ $size: string; $background: string }>`
  height: ${props => props.$size};
  width: ${props => props.$size};
  border-radius: ${props => props.$size};
  background-color: ${props => props.$background};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
