import { Action, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import contratoReducer from "./contrato/contrato_slice";
import contratadaReducer from "./contratada/contratada_slice";
import contratanteReducer from "./contratante/contratante_slice";
import kitReducer from "./kit/kit_slice";
import kitViewReducer from "./kit/view_kit_slice";
import kitNewReducer from "./kit/new_kit_slice";
import novoContratoReducer from "./contrato/new_contrato_slice";
import servicoReducer from "./servico/servico_slice";
import viewServicoReducer from "./servico/view_servico_slice";
import viewContratoReducer from "./contrato/view_contrato_slice";
import faturaReducer from "./fatura/fatura_slice";
import viewFaturaSliceReducer from "./fatura/view_fatura_slice";
import modeloReducer from "./modelo/modelo_slice";
import viewModeloReducer from "./modelo/view_modelo_slice";
import listEnumReducer from "./list_enum_slice";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    contrato: contratoReducer,
    contratada: contratadaReducer,
    contratante: contratanteReducer,
    kit: kitReducer,
    servico: servicoReducer,
    view_servico: viewServicoReducer,
    novo_contrato: novoContratoReducer,
    view_contrato: viewContratoReducer,
    fatura: faturaReducer,
    view_fatura: viewFaturaSliceReducer,
    view_kit: kitViewReducer,
    new_kit: kitNewReducer,
    modelo: modeloReducer,
    view_modelo: viewModeloReducer,
    list_enum: listEnumReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
