import { createBrowserRouter } from "react-router-dom";
import ContratoPage from "view/Contrato/index";
import KitPage from "view/Kit/index";
import ServicoPage from "view/Servico/index";
import CadastroPage from "view/Cadastro/index";
import HomePage from "view/Home/index";
import FuturamentoPage from "view/Faturamento";
import FaturaPage from "view/Fatura";
import PagamentoPage from "view/Pagamento";
import ViewFaturaPage from "view/Fatura/pages/ViewFatura";
import ViewKitPage from "view/Kit/pages/ViewKIt";
import NewKitPage from "view/Kit/pages/NewKit";
import ModeloPage from "view/Modelo";
import NovoContratoPage from "view/Contrato/page/NewContrato";
import ViewContratoPage from "view/Contrato/page/EditContrato";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/contrato",
    children: [
      {
        element: <ContratoPage />,
        index: true,
      },
      {
        path: "/contrato/novo_contrato",
        element: <NovoContratoPage />,
      },
      {
        path: "/contrato/editar_contrato/:id",
        element: <ViewContratoPage />,
      },
    ],
  },
  {
    path: "/cadastro",
    children: [
      {
        element: <CadastroPage />,
        index: true,
      },
      {
        path: "/cadastro/kit",
        children: [
          {
            element: <KitPage />,
            index: true,
          },
          { path: "/cadastro/kit/:id", element: <ViewKitPage />, index: true },
          { path: "/cadastro/kit/novo", element: <NewKitPage />, index: true },
        ],
      },
      {
        path: "/cadastro/servico",
        element: <ServicoPage />,
      },
      {
        path: "/cadastro/modelo",
        children: [
          {
            element: <ModeloPage />,
            index: true,
          },
        ],
      },
    ],
  },
  {
    path: "/faturamento",
    children: [
      {
        element: <FuturamentoPage />,
        index: true,
      },
      {
        path: "/faturamento/fatura",
        children: [
          {
            element: <FaturaPage />,
            index: true,
          },
          { path: "/faturamento/fatura/:id", element: <ViewFaturaPage /> },
        ],
      },
      {
        path: "/faturamento/pagamento",
        element: <PagamentoPage />,
      },
    ],
  },
]);
