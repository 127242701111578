import { ServicoHttp } from "gateways/repo/servico_http_client";
import { useState } from "react";
import useServico from "./useServico";

const useNewServico = () => {
  const http = new ServicoHttp();
  const [isLoadingServico, setLoading] = useState(false);
  const [isServicoError, setError] = useState(false);
  const { getServicos } = useServico();
  const addNewServico = async (nome: string, descricao?: string) => {
    setLoading(true);
    setError(false);
    try {
      await http.addNewServico({ ID: 0, Nome: nome, Descricao: descricao });
      getServicos();
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    addNewServico,
    isLoadingServico,
    isServicoError,
  };
};
export default useNewServico;
