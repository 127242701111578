import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const TertiaryButtonContent = styled.div<{
  $isfilled: any;
  $isdisable: any;
  $issmall: any;
}>`
  padding: 2px;
  button {
    cursor: pointer;
    background-color: ${props => buttonBackgroundColor(props.$isdisable, props.$isfilled)};
    border-radius: 8px;
    height: ${props => (props.$issmall ? "40px" : "48px")};
    min-width: ${props => (props.$issmall ? "112px" : "192px")};
    border: 0px;
    color: ${props => buttonColor(props.$isdisable, props.$isfilled)};
    transition: 300ms;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: ${props => (props.$issmall ? "0 8px" : "0 16px")};
    border: ${props => borderColor(props.$isdisable, props.$isfilled)};
    text-align: center;
    font-family: Montserrat;
    font-size: ${props => (props.$issmall ? "14px" : "16px")};
    font-style: normal;
    font-weight: 600;
    line-height: ${props => (props.$issmall ? "30px" : "30px")};
  }
  ${props =>
    !props.$isdisable
      ? ` button:hover {
    background-color: 
 ${props.$isfilled ? billing_theme.pallete.tertiary?.A100 : billing_theme.pallete.tertiary?.A200};
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border: 
    ${props.$isfilled ? ` 2px solid ${billing_theme.pallete.tertiary?.A400}` : ""};
    color: 
    ${props.$isfilled ? billing_theme.pallete.tertiary?.A400 : billing_theme.pallete.tertiary?.A25};
  }

  button:active {
    background-color:
    ${props.$isfilled ? billing_theme.pallete.tertiary?.A100 : billing_theme.pallete.tertiary?.A600};
    box-shadow:
      inset 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border: 
    ${props.$isfilled ? `2px solid  ${billing_theme.pallete.tertiary.A200}` : ""};
    color: 
    ${props.$isfilled ? billing_theme.pallete.tertiary?.A200 : billing_theme.pallete.tertiary?.A25};
  }`
      : ""}
`;

const buttonBackgroundColor = (isDisable: boolean, isFilled: boolean) => {
  if (isDisable) return billing_theme.pallete.tertiary?.A25;
  if (isFilled) return billing_theme.pallete.tertiary?.A100;
  return billing_theme.pallete.specific.T_300_400;
};
const buttonColor = (isDisable: boolean, isFilled: boolean) => {
  if (isDisable) return billing_theme.pallete.tertiary?.A400;
  if (isFilled) return billing_theme.pallete.tertiary?.A400;
  return billing_theme.pallete.specific.T_50_25;
};
const borderColor = (isDisable: boolean, isFilled: boolean) => {
  if (isDisable) return "";
  if (isFilled) return `solid 2px ${billing_theme.pallete.tertiary?.A400}`;
  return "";
};
