import LineSkeleton from "components/skeleton/line_skeleton";
import {
  KitListButton,
  KitListContent,
  KitListHeader,
  SkeletonKitListItensContainer,
} from "./style";
import React from "react";
const SkeletonKitList: React.FC<{}> = () => {
  const array = ["", "", "", ""];
  return (
    <KitListContent>
      <KitListHeader>
        {array.map((a, index) => (
          <LineSkeleton key={index} height={30} width={150} />
        ))}
      </KitListHeader>

      <SkeletonKitListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonKitListItensContainer>
      <SkeletonKitListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonKitListItensContainer>
      <SkeletonKitListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonKitListItensContainer>
      <SkeletonKitListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonKitListItensContainer>
      <SkeletonKitListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonKitListItensContainer>
      <SkeletonKitListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonKitListItensContainer>
    </KitListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <KitListButton>
      <LineSkeleton height={30} width={90} />
    </KitListButton>
  );
};

export default SkeletonKitList;
