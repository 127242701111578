import React from "react";
import { ErrorIconContent, ErrorTextContent, ErrorToastContainer } from "./style";
import { SymbolCodepoints } from "react-material-symbols";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body4 } from "components/text/body/style";

const ErrorToastComp: React.FC<{
  icon?: SymbolCodepoints;
  title: string;
  message?: string;
}> = ({ icon, title, message }) => {
  return (
    <ErrorToastContainer>
      <ErrorIconContent>
        <BillingIconSharp
          icon={icon ?? "error"}
          color={billing_theme.pallete.tertiary.A50}
          size={30}
        />
      </ErrorIconContent>
      <ErrorTextContent>
        <Heading5 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {title}
        </Heading5>
        {message && <Body4 color={billing_theme.pallete.tertiary.A400}>{message}</Body4>}
      </ErrorTextContent>
    </ErrorToastContainer>
  );
};

export default ErrorToastComp;
