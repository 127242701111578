import React, { useState } from "react";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { NovoContratoKit } from "./style";

import TableComponentKit from "./components/table";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingSelectInput from "components/input/select";
import TertiaryButton from "components/button/tertiaty_button";
import { addKitState } from "store/contrato/new_contrato_slice";
import { KitComplementar } from "shared/models/contrato_post.model";

const NovoContratoKitComp: React.FC = () => {
  const data = useSelector((state: RootState) => state.novo_contrato);
  const kit = useSelector((state: RootState) => state.kit);

  const dispatch = useAppDispatch();

  const [id, setId] = useState<number>();

  const addKit = () => {
    const kitScope: KitComplementar = { ID: 0, Kit_ID: id! };
    dispatch(addKitState(kitScope));
  };

  return (
    <NovoContratoKit>
      <div className="header">
        <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A600}>
          Seleção de Kits
        </Heading5>
      </div>
      <div className="select_content">
        <BillingSelectInput
          onChange={val => {
            setId(Number(val?.value!));
          }}
          options={kit.options}
          placeholder={"Selecione um  Contrato Inicial"}
        />
        <TertiaryButton label={"Adicionar"} isSmall onTap={() => addKit()} />
      </div>
      {data.kitsComplementares.length == 0 ? (
        <div className="no_content">
          <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A400}>
            Você ainda não selecionou nenhum kit
          </Heading5>
        </div>
      ) : (
        <>
          <TableComponentKit kits={data.kitsComplementares} />
        </>
      )}
    </NovoContratoKit>
  );
};
export default NovoContratoKitComp;
