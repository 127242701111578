import BillingIconSharp from "components/billing_icon/sharp_icon";
import { Body3 } from "components/text/body/style";
import React from "react";
import "react-material-symbols/outlined";
import billing_theme from "style/theme_colors";
import { MenuButton } from "./style";
import { useNavigate } from "react-router-dom";
import { SymbolCodepoints } from "react-material-symbols";

const BillingMenuButton: React.FC<{
  path: string;
  name: string;
  icon: SymbolCodepoints;
  page: string;
}> = ({ path, name, icon, page }) => {
  const navigate = useNavigate();
  return (
    <MenuButton selected={page == path} onClick={() => navigate(path)}>
      <BillingIconSharp
        icon={icon}
        color={
          page == path ? billing_theme.light.tertiary?.A25! : billing_theme.pallete.tertiary?.A400!
        }
      />

      <Body3
        fontWeight={page == path ? 600 : 400}
        color={
          page == path ? billing_theme.light.tertiary?.A25! : billing_theme.pallete.tertiary?.A400!
        }
      >
        {name}
      </Body3>
    </MenuButton>
  );
};

export default BillingMenuButton;
