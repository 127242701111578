import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const BillingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    text-align: left;
  }
  td {
    padding: 0px 24px;
    text-align: center;
  }
  thead tr {
    height: 64px;
  }
  tbody {
    border-radius: 8px;
    border: solid 0.5px ${billing_theme.pallete.tertiary.A200};
  }
  tbody tr:nth-child(odd) {
    background-color: ${billing_theme.pallete.specific.T_50_25};
  }
  tbody tr:nth-child(even) {
    background-color: ${billing_theme.pallete.specific.T_25_50};
  }
  tbody tr {
    height: 64px;
  }
`;
