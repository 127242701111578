import { toastError, toastInfo } from "components/toast/billing_toast";
import { ModeloHttp } from "gateways/repo/modelo_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ModeloResponseList } from "shared/models/modelo_list_response.model";
import { setLoadingModelo, setModeloFilter, setModeloList } from "store/modelo/modelo_slice";
import { RootState, useAppDispatch } from "store/store";

const useModelo = () => {
  const http = new ModeloHttp();
  const dispatch = useAppDispatch();
  const [isModeloError, setisModeloError] = useState(false);
  const [openDeleteModalModelo, setDeleteModalModelo] = useState<boolean>();
  const [deleteIdModelo, setDeleteIdModelo] = useState<string | number>();
  const [loadModal, setLoadingModal] = useState(false);
  const modelo = useSelector((state: RootState) => state.modelo);

  const getListModelos = async () => {
    setisModeloError(false);
    dispatch(setLoadingModelo(true));
    try {
      const data = await http.getListModelos();
      dispatch(setModeloList(data.Lista));
    } catch (e) {
      setisModeloError(true);
      console.log(e);
    } finally {
      dispatch(setLoadingModelo(false));
    }
  };
  const filterModelo = (key: number) => {
    const filter: ModeloResponseList[] = [];

    modelo.list!.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => a.Nome.toLowerCase().localeCompare(b.Nome.toLowerCase()));

        break;

      default:
        filter.sort((a, b) => a.Tipo.toLowerCase().localeCompare(b.Tipo.toLowerCase()));
    }

    dispatch(setModeloFilter(filter));
  };

  const filterModeloReverse = (key: number) => {
    const filter: ModeloResponseList[] = [];

    modelo.list!.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => b.Nome.toLowerCase().localeCompare(a.Nome.toLowerCase()));
        break;

      default:
        filter.sort((a, b) => b.Tipo.toLowerCase().localeCompare(a.Tipo.toLowerCase()));
    }

    dispatch(setModeloFilter(filter));
  };

  const filterByName = (name: string) => {
    const filter: ModeloResponseList[] = [];

    dispatch(setModeloFilter([]));
    modelo.list!.forEach(el => {
      if (el.Nome.toLowerCase().includes(name.toLowerCase())) {
        filter.push(el);
      }
    });

    dispatch(setModeloFilter(filter));
  };

  const deleteModelo = async () => {
    setLoadingModal(true);
    try {
      await http.deleteModelo(deleteIdModelo!);

      toastInfo({ title: "Modelo excluido!" });
      setDeleteModalModelo(false);
      getListModelos();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      setLoadingModal(false);
    }
  };
  return {
    getListModelos,
    filterByName,
    filterModelo,
    filterModeloReverse,
    isModeloError,
    openDeleteModalModelo,
    setDeleteModalModelo,
    setDeleteIdModelo,
    deleteModelo,
    loadModal,
    deleteIdModelo,
  };
};
export default useModelo;
