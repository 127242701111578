import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const TextInputContent = styled.div<{
  $width?: number;
  $hasicon: boolean;
}>`
  position: relative;
  textarea {
    border-radius: 4px;
    border: 1px solid ${billing_theme.pallete.tertiary?.A200};
    font-size: 14px;
    line-height: 27px;
    margin: 0px;
    padding: ${props => (props.$hasicon ? "6px 44px 6px 12px" : "6px 12px 6px 12px")};
    transition: 300ms;
    color: ${billing_theme.pallete.tertiary?.A400};
    font-weight: 500;
    width: ${props =>
      props.$width ? props.$width - (props.$hasicon ? 56 : 12) + "px" : iconSize(props.$hasicon)};
    font-family: Montserrat;
    background-color: ${billing_theme.pallete.specific.T_50_25};
  }

  textarea::placeholder {
    color: ${billing_theme.pallete.tertiary?.A400};
    font-weight: 400;
  }
  textarea:focus {
    border: 1px solid ${billing_theme.pallete.primary?.A100};
    outline: 0px solid ${billing_theme.pallete.primary?.A100};
    box-shadow:
      0px 3px 1px -2px #00000027,
      0px 2px 2px 0px #00000017,
      0px 1px 5px 0px #00000017;
  }
  textarea:hover {
    box-shadow:
      0px 3px 1px -2px #00000027,
      0px 2px 2px 0px #00000017,
      0px 1px 5px 0px #00000017;
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 44px;
`;
const iconSize = (hasIcon: boolean) => {
  return hasIcon ? "calc(100% - 56px)" : "calc(100% - 24px)";
};
