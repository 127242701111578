import { ThemePallete } from "shared/models/theme_colors.model";

export class ThemeControler {
  light: ThemePallete = {
    primary: {
      A50: "#FBF5FF",
      A100: "#E9CCFF",
      A200: "#C780FF",
      A300: "#9A1AFF",
      A400: "#5B00A3",
      A500: "#2E0052",
      A600: "#0E001A",
    },
    secundary: {
      A50: "#FFFAFE",
      A100: "#FFD6F3",
      A200: "#FF99E1",
      A300: "#FF4DCB",
      A400: "#F800B0",
      A500: "#8F0065",
      A600: "#3D002B",
    },
    tertiary: {
      A25: "#FFFFFF",
      A50: "#FFF6FF",
      A100: "#BEBEBE",
      A200: "#858585",
      A300: "#59565F",
      A400: "#3D354A",
      A500: "#202020",
      A600: "#141414",
    },
    success: {
      A50: "#51B12F",
      A100: "#3A8022",
    },
    warning: {
      A50: "#D8C041",
      A100: "#EFD02A",
    },
    error: {
      A50: "#E91D1D",
      A100: "#9C0303",
    },
    overlay: "#00000066",

    specific: {
      T_300_400: "#3D354A",
      T_300_P_300: "#9A1AFF",
      T_50_25: "#FFFFFF",
      T_25_50: "#FFF6FF",
    },
    box_shadow: "#24242429",
  };
  dark: ThemePallete = {
    primary: {
      A50: "#0E001A",
      A100: "#5B00A3",
      A200: "#9A1AFF",
      A300: "#C780FF",
      A400: "#E9CCFF",
      A500: "#2E0052",
      A600: "#FBF5FF",
    },
    secundary: {
      A50: "#3D002B",
      A100: "#F800B0",
      A200: "#FF4DCB",
      A300: "#FF99E1",
      A400: "#FFD6F3",
      A500: "#FFFAFE",
      A600: "#FFFAFE",
    },
    tertiary: {
      A25: "#202020",
      A50: "#141414",
      A100: "#3D354A",
      A200: "#59565F",
      A300: "#858585",
      A400: "#BEBEBE",
      A500: "#FFFFFF",
      A600: "#FFF6FF",
    },
    success: {
      A50: "#3A8022",
      A100: "#51B12F",
    },
    warning: {
      A50: "#EFD02A",
      A100: "#D8C041",
    },
    error: {
      A50: "#9C0303",
      A100: "#E91D1D",
    },

    overlay: "#ffffff66",
    specific: {
      T_300_400: "#858585",
      T_300_P_300: "#858585",
      T_50_25: "#141414",
      T_25_50: "#202020",
    },
    box_shadow: "#dfdfdf29",
  };
  isDark = () => localStorage.getItem("isDark") == "true";

  changeTheme = () => {
    if (this.isDark()) {
      localStorage.setItem("isDark", "false");
      window.location.reload();
      return;
    }
    localStorage.setItem("isDark", "true");
    window.location.reload();
  };

  pallete = this.isDark() ? this.dark : this.light;
}

const billing_theme = new ThemeControler();

export default billing_theme;
