import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const BillingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    text-align: center;
  }
  td {
    padding: 0px 8px;
    min-width: 160px;
    width: 100%;
  }
  thead tr {
    height: 64px;
  }
  tbody {
    border-radius: 4px;
    border: solid 0.5px ${billing_theme.pallete.tertiary.A100};
    max-height: 400px;
  }
`;
export const BillingTableContent = styled.div`
  padding-bottom: 16px;
  width: 100%;
  padding-right: 2px;
`;
export const TableContent = styled.div`
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 10px 0px;
  }
  h4 {
    text-align: center;
  }
`;

export const RowItem = styled.tr`
  background-color: ${billing_theme.pallete.specific.T_25_50};
  height: 48px;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary.A100};
`;
export const NewKitListIcon = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 0px;
  height: 24px;
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: 100%;
  justify-content: center;
  .button {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
  }
`;
