import { toastError } from "components/toast/billing_toast";
import { FaturaHttp } from "gateways/repo/fatura_http_client";
import { ModeloHttp } from "gateways/repo/modelo_http_client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { setViewFatura } from "store/fatura/view_fatura_slice";
import { useAppDispatch } from "store/store";

const useViewFatura = () => {
  const params = useParams();
  const http = new FaturaHttp();
  const dispatch = useAppDispatch();

  const [isFaturaError, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getFaturaById = async () => {
    setError(false);
    setIsLoading(true);
    try {
      const data = await http.getFaturaById(params.id!);
      dispatch(setViewFatura(data));
    } catch (e) {
      toastError({ title: "Algo deu errado!", message: "Por favor, tente novamente!" });
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getFaturaById,
    isFaturaError,
    isLoading,
  };
};
export default useViewFatura;
