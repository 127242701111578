import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const InsideMenuButtonComp = styled.button<{
  selected: boolean;
}>`
  display: flex;
  height: 35px;
  padding: 0px 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  flex-shrink: 0;
  align-self: stretch;
  color: ${props =>
    props.selected ? billing_theme.pallete.primary?.A400 : billing_theme.pallete.tertiary?.A600};
  border: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  gap: 6px;

  .menu-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;
