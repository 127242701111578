import BillingHeader from "components/header";
import React, { createContext } from "react";
import { ChildWrapper, ScaffoldComponent, Wrapper } from "./style";
import BillingMenu from "components/menu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import billing_theme from "style/theme_colors";
export const ThemeProvider = createContext(billing_theme);

const Scaffold: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider.Provider value={billing_theme}>
      <Wrapper>
        <BillingHeader />
        <ScaffoldComponent>
          <BillingMenu />

          <div></div>
          <ChildWrapper>{children}</ChildWrapper>
        </ScaffoldComponent>
        <ToastContainer autoClose={5000} />
      </Wrapper>
    </ThemeProvider.Provider>
  );
};

export default Scaffold;
