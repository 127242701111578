import LineSkeleton from "components/skeleton/line_skeleton";
import {
  FaturaListButton,
  FaturaListContent,
  FaturaListHeader,
  SkeletonFaturaListItensContainer,
} from "./style";
import React from "react";
const SkeletonFaturaList: React.FC<{}> = () => {
  const array = ["", "", "", "", ""];
  return (
    <FaturaListContent>
      <FaturaListHeader>
        {array.map((el, index) => (
          <LineSkeleton key={index} height={30} width={150} />
        ))}
      </FaturaListHeader>

      <SkeletonFaturaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonFaturaListItensContainer>
      <SkeletonFaturaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonFaturaListItensContainer>
      <SkeletonFaturaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonFaturaListItensContainer>
      <SkeletonFaturaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonFaturaListItensContainer>
      <SkeletonFaturaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonFaturaListItensContainer>
    </FaturaListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <FaturaListButton>
      <LineSkeleton height={30} width={90} />
    </FaturaListButton>
  );
};

export default SkeletonFaturaList;
