import styled from "styled-components";

export const NovoContratoConfiguracao = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .configInput {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 8px;
  }

  .prorata {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
