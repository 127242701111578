import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/routes";
import { registerLicense } from "@syncfusion/ej2-base";
import { Provider } from "react-redux";
import { store } from "store/store";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { syncfusion_translate } from "assets/json/translate_syncfusion";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale/pt-BR";
import GlobalStyle from "style";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
registerLocale("pt-BR", ptBR);
registerLicense(process.env.REACT_APP_REGISTER_LICENSE_SYNC!);
L10n.load(syncfusion_translate);
setCulture("pt-BR");
setDefaultLocale("pt-BR");
root.render(
  <Provider store={store}>
    <GlobalStyle />
    <RouterProvider router={router} />
  </Provider>
);
