import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const KitListContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: ${billing_theme.pallete.specific.T_50_25};
  width: 98%;
  margin: 0 auto;
  padding: 24px 0px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 0px 24px 1px rgba(190, 190, 190, 0.3803921569);
  -moz-box-shadow: 0px 0px 24px 1px rgba(190, 190, 190, 0.3803921569);
  box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const KitListHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 20%);
  width: 100%;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  margin-bottom: 16px;
  padding-bottom: 4px;
  margin-top: 16px;
  justify-content: space-around;
  text-align: center;
`;
export const KitListButton = styled.button`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 0px 0px 0px;
  height: 38px;
  cursor: pointer;
  gap: 10%;
  transition: 300ms;

  h6 {
    line-height: 16px;
  }
`;

export const SkeletonKitListItensContainer = styled.div`
  width: 98%;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 8px;
`;
export const KitListItems = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 5px;
  height: 24px;
`;
export const KitListIcon = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 0px;
  height: 24px;
`;
