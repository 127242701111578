import { toastError, toastSuccess } from "components/toast/billing_toast";
import { FaturaHttp } from "gateways/repo/fatura_http_client";
import { useState } from "react";
import { setNovaFaturalModalState } from "store/fatura/fatura_slice";
import { useAppDispatch } from "store/store";
import useFatura from "./useFatura";

const useNovaFatura = () => {
  const http = new FaturaHttp();
  const { getFatura } = useFatura();
  const dispatch = useAppDispatch();

  const [isFaturaError, setError] = useState(false);
  const [isLoadingNovaFatura, setLoading] = useState(false);

  const addNovaFatura = async (dataRef: string, contratanteId: number) => {
    setLoading(true);
    setError(false);
    try {
      await http.addtNewFatura({ DataRef: dataRef!, Contratante_ID: contratanteId! });
      toastSuccess({ title: "Nova fatura adicionada" });
      dispatch(setNovaFaturalModalState(false));
      getFatura();
    } catch (e) {
      setError(true);
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      setLoading(true);
      setError(false);
    }
  };

  return {
    isFaturaError,
    isLoadingNovaFatura,
    addNovaFatura,
  };
};
export default useNovaFatura;
