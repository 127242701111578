import { EnumHttp } from "gateways/repo/enum_http_client";
import { useState } from "react";
import { setEnumBilling } from "store/list_enum_slice";
import { useAppDispatch } from "store/store";

const useBillingEnum = () => {
  const http = new EnumHttp();
  const dispatch = useAppDispatch();
  const [isLoadingEnum, setLoading] = useState(true);

  const getEnums = async () => {
    setLoading(true);
    try {
      const data = await http.getListEnum();

      dispatch(setEnumBilling(data));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    getEnums,
    isLoadingEnum,
  };
};
export default useBillingEnum;
