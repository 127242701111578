import { Heading5, Heading6 } from "components/text/heading/style";
import { DeleteKitButton, DeleteKitContent, DeleteKitHeader, KitListErrorContent } from "./style";
import React from "react";
import billing_theme from "style/theme_colors";
import PrimaryButton from "components/button/billing_button";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import { Body3 } from "components/text/body/style";

const KitDeleteContent: React.FC<{
  onDelete: () => void;
  onClose: () => void;
  kit: string;
  onLoading: boolean;
}> = ({ onDelete, onClose, kit, onLoading }) => {
  const close = () => {
    onClose();
  };
  return (
    <KitListErrorContent>
      <DeleteKitHeader>
        <BillingIconSharp icon={"delete"} color={billing_theme.pallete.error.A100} size={32} />
        <BillingIconSharp
          icon={"close"}
          color={billing_theme.pallete.primary.A400}
          size={32}
          onTap={close}
        />
      </DeleteKitHeader>
      <DeleteKitContent>
        <Heading5 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
          Deseja excluir o kit: {kit}
        </Heading5>
        <Body3 color={billing_theme.pallete.tertiary.A600} fontWeight={500}>
          Ao excluir este kit, os dados serão movidos para a lixeira e posteriormente poderá ser
          recuperado.
        </Body3>
      </DeleteKitContent>
      <DeleteKitButton>
        <PrimaryButton label={"Cancelar"} onTap={() => onClose()} isSmall disable={onLoading} />
        <PrimaryButton
          label={"Deletar mesmo assim"}
          onTap={() => onDelete()}
          isSmall
          disable={onLoading}
        />
      </DeleteKitButton>
    </KitListErrorContent>
  );
};

export default KitDeleteContent;
