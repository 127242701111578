import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const BillingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    text-align: center;
  }
  td {
    padding: 0px 8px;
    width: 100%;
    min-width: 160px;
  }
  thead tr {
    height: 64px;
  }
  tbody {
    border-radius: 4px;
    border: solid 0.5px ${billing_theme.pallete.tertiary.A100};
    overflow-y: scroll;
    max-height: 400px;
    display: block;
  }
  thead {
    display: block;
  }
`;
export const BillingTableContent = styled.div`
  overflow-x: scroll;
  padding-bottom: 16px;
  width: 100%;
  padding-right: 2px;
`;
export const TableContent = styled.div`
  width: calc(100% - 32px);

  h4 {
    text-align: center;
  }
`;

export const RowItem = styled.tr`
  background-color: ${billing_theme.pallete.specific.T_25_50};
  height: 48px;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary.A100};
`;
