import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FaturaResponseModel } from "shared/models/fatura_response.model";

const initialState: FaturaResponseModel = {
  Objeto: {
    ID: 0,
    DataRef: "",
    Contratante: "",
    ValorFatura: 0,
    Instalados: [],
    Estoque: [],
    MaoObras: [],
    Reajustes: [],
  },
  NivelPermissao: 0,
  Tipo: 0,
  Mensagem: "",
  DataHoraServidor: "",
};

const view_fatura_slice = createSlice({
  name: "view_fatura",
  initialState,
  reducers: {
    setViewFatura(state, action: PayloadAction<FaturaResponseModel>) {
      state.Objeto = action.payload.Objeto;
    },
  },
});

export const { setViewFatura } = view_fatura_slice.actions;
export default view_fatura_slice.reducer;
