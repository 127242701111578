import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const LoadingComponent = styled.div`
  width: 98%;
  margin: 12px auto;
`;
export const NoContentTable = styled.div`
  width: 100%;
  height: 200px;
  border: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  background: ${billing_theme.pallete.specific.T_25_50};
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-top: 18px;
`;
