import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const DatePickerContent = styled.div<{ width?: string }>`
  .react-datepicker-wrapper {
    height: 39px;
    width: ${props => (props.width ? props.width : "100%")};
  }
  .react-datepicker__input-container {
    height: 100%;
  }
  .react-datepicker__input-container input {
    height: 100%;
    border-radius: 4px;
    border: 0.5px solid ${billing_theme.pallete.tertiary?.A200};
    margin: 0px !important;
    padding: 0px 7px;
    background-color: ${billing_theme.pallete.specific.T_50_25};
    width: calc(100% - 14px);
    color: ${billing_theme.pallete.tertiary?.A600};
  }
  .react-datepicker__input-container input::placeholder {
    color: ${billing_theme.pallete.tertiary?.A400};
    font-weight: 400;
  }
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 13px;
    box-sizing: content-box;
    right: 0px;
  }

  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: ${billing_theme.pallete.primary?.A400};
    color: ${billing_theme.pallete.primary?.A50};
    border-radius: 100%;
    height: 14px;
    width: 14px;
    padding: 2px;
    font-size: 8px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "X";
  }
`;
