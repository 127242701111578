import { ViewFaturaContainer, ViewFaturaDetails, ViewFaturaInfo } from "./style";
import { Body1, Body2 } from "components/text/body/style";
import { Heading3, Heading4 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { toDate } from "utils/stringUtils";
import { toMoney } from "utils/numberUtils";
import ViewFaturaItensTable from "../ViewFaturaInstalacaoTable";
import ViewFaturaEstoqueTable from "../ViewFaturaEstoque";
import ViewFaturaMaoDeObraTable from "../ViewFaturaMaoDeObra";
import ViewFaturaReajustesTable from "../ViewFaturaReajustes";
import BillingTab from "components/tab_slider";

const FaturaViewMain: React.FC = () => {
  const viewFatura = useSelector((state: RootState) => state.view_fatura);

  return (
    <ViewFaturaContainer>
      <>
        <Heading4 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          Detalhe da fatura
        </Heading4>

        <ViewFaturaInfo>
          <ViewFaturaDetails>
            <Body2 color={billing_theme.pallete.tertiary?.A400}>Id:</Body2>
            <Heading4 color={billing_theme.pallete.tertiary?.A400} fontWeight={500}>
              {viewFatura.Objeto.ID}
            </Heading4>
          </ViewFaturaDetails>
          <ViewFaturaDetails>
            <Body2 color={billing_theme.pallete.tertiary?.A400}>Contratante:</Body2>
            <Heading4 fontWeight={500} color={billing_theme.pallete.tertiary?.A400}>
              {viewFatura.Objeto.Contratante}
            </Heading4>
          </ViewFaturaDetails>
          <ViewFaturaDetails>
            <Body2 color={billing_theme.pallete.tertiary?.A400}>Data:</Body2>
            <Heading4 fontWeight={500} color={billing_theme.pallete.tertiary?.A400}>
              {toDate(viewFatura.Objeto.DataRef, "MM/YYYY")}
            </Heading4>
          </ViewFaturaDetails>
          <ViewFaturaDetails>
            <Body2 color={billing_theme.pallete.tertiary?.A400}>Valor da fatura:</Body2>
            <Heading4 fontWeight={500} color={billing_theme.pallete.tertiary?.A400}>
              {toMoney(viewFatura.Objeto.ValorFatura)}
            </Heading4>
          </ViewFaturaDetails>
        </ViewFaturaInfo>
      </>
      <BillingTab titles={["Equipamentos instalados", "Estoque", "Mão de obra", "Reajustes"]}>
        <ViewFaturaItensTable />
        <ViewFaturaEstoqueTable />
        <ViewFaturaMaoDeObraTable />
        <ViewFaturaReajustesTable />
      </BillingTab>
    </ViewFaturaContainer>
  );
};

export default FaturaViewMain;
