import React from "react";
import { BillingTable, BillingTableContent, RowItem, TableContent } from "./style";
import { Heading4, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body3 } from "components/text/body/style";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Reajuste } from "shared/models/fatura_response.model";
import { toMoney } from "utils/numberUtils";
import { toDate } from "utils/stringUtils";
import { NoContentTable } from "../../style";

const ViewFaturaReajustesTable: React.FC = () => {
  const viewFatura = useSelector((state: RootState) => state.view_fatura);

  return (
    <TableContent>
      <Heading4 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
        Reajustes
      </Heading4>
      {viewFatura.Objeto.Reajustes.length == 0 && (
        <NoContentTable>
          <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
            Nenhuma item a ser mostrado!
          </Heading6>
        </NoContentTable>
      )}
      {viewFatura.Objeto.Reajustes.length != 0 && (
        <BillingTableContent>
          <BillingTable>
            <thead>
              <HeadTable />
            </thead>
            <tbody>
              {viewFatura.Objeto.Reajustes.map(el => (
                <ItemReajustes key={el.ID} estoque={el} />
              ))}
            </tbody>
          </BillingTable>
        </BillingTableContent>
      )}
    </TableContent>
  );
};
const HeadTable: React.FC = () => {
  return (
    <tr>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Numero de contrato
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Data
        </Heading6>
      </td>

      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Quantidade
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Resumo Kits
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Valor unitario
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Periodo
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Indice
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Valor Indice
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Valor Calculo
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Valor Reajuste
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Valor Un Reajust
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Mês Reajuste
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Status
        </Heading6>
      </td>
    </tr>
  );
};
const ItemReajustes: React.FC<{ estoque: Reajuste }> = ({ estoque }) => {
  return (
    <RowItem>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.NumeroContratoBilling}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toDate(estoque.Data)}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.Quantidade}
        </Body3>
      </td>

      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.ResumoKits}
        </Body3>
      </td>

      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.ValorUnitario)}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.Periodo}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.Indice}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.ValorIndice)}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.ValorCalculo)}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.ValorReajuste)}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.ValorUnitarioReajustado)}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toDate(estoque.MesReajuste)}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.StatusContrato}
        </Body3>
      </td>
    </RowItem>
  );
};
export default ViewFaturaReajustesTable;
