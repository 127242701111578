import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const NovoContratoContent = styled.div`
  width: 100%;

  .contain {
    padding: 16px 16px;
  }
  .css-13cymwt-control {
    border-radius: 8px;
    border: solid 0.5px ${billing_theme.pallete.tertiary?.A200};
  }

  .css-13cymwt-control:hover {
    border-radius: 8px;
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
  }
  .css-t3ipsp-control:hover {
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
    border-color: ${billing_theme.pallete.primary?.A200};
  }

  .css-13cymwt-control:focus {
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
    border-color: ${billing_theme.pallete.primary?.A200};
  }
  .css-1jqq78o-placeholder {
    color: ${billing_theme.pallete.tertiary?.A300};
    font-weight: 600;
    font-size: 13px;
  }

  .css-1dimb5e-singleValue {
    color: ${billing_theme.pallete.tertiary?.A400};
    font-size: 13px;
    font-weight: 500;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
export const NovoContratoHeader = styled.div`
  padding: 16px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.primary?.A300};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
`;

export const NovoContratoForm = styled.div`
  background-color: ${billing_theme.pallete.specific.T_50_25};
  -webkit-box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  -moz-box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: calc(100% - 32px);
  margin: 20px auto;
  border-radius: 12px;
  padding: 16px;
  min-height: 100%;
  float: left;
  position: relative;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
  }
`;

export const NovoContratoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const NovoContratosForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .contrantes_contratoInicial {
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 16px;
  }
`;
