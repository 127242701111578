import { useEffect, useState } from "react";
import {
  FaturaListButton,
  FaturaListContent,
  FaturaListHeader,
  FaturaListIcon,
  FaturaListItems,
  FaturaListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { FaturaItemModel } from "shared/models/fatura_list_response.model";
import UseFatura from "hooks/useFatura";
import { toDate } from "utils/stringUtils";
import { toMoney } from "utils/numberUtils";
import FaturaEmptyContent from "../fatura_empty_list";
import ModalBilling from "components/modal";
import FaturaDeleteContent from "../fatura_delete_modal";
import { useNavigate } from "react-router-dom";

const FaturaList: React.FC<{}> = () => {
  const fatura = useSelector((state: RootState) => state.fatura);
  const {
    filterFatura,
    filterFaturaReverse,
    openModalDelete,
    deleteFatura,
    setOpenDeleteModals,
    openDeleteModals,
    idNameDelete,
    isLoadingDelete,
  } = UseFatura();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<FaturaItemModel[]>([]);
  const [filterState, setFilterState] = useState("normal");
  const navigate = useNavigate();
  const selectFilter = (id: string, index: number) => {
    if (filter != id) setFilterState("normal");

    if (filterState == "normal") {
      filterFatura(index);
      setFilterState("reverse");
    } else {
      filterFaturaReverse(index);
      setFilterState("normal");
    }

    setFilter(id);
  };

  useEffect(() => {
    if (fatura.enable_filter || fatura.filterList.length != 0) {
      setData(fatura.filterList);
      return;
    }
    setData(fatura.list);
  });

  return (
    <FaturaListContent>
      {fatura.enable_filter && data.length == 0 && (
        <FaturaEmptyContent clear={() => console.log("aas")} />
      )}

      {data.length != 0 && (
        <FaturaListHeader>
          <ListButton
            label="Contratante"
            filterState={filterState}
            click={() => selectFilter("Contratante", 2)}
            selectedFilter={filter}
          />
          <ListButton
            label="Valor da fatura"
            filterState={filterState}
            click={() => selectFilter("Valor da fatura", 3)}
            selectedFilter={filter}
          />
          <ListButton
            label="Data"
            filterState={filterState}
            click={() => selectFilter("Data", 4)}
            selectedFilter={filter}
          />

          <ListButton
            label="Opções"
            filterState={filterState}
            click={() => console.log("oi")}
            selectedFilter={"options"}
          />
        </FaturaListHeader>
      )}

      {data.map(item => (
        <FaturaListItensContainer key={item.ID}>
          <ListItens label={item.Contratante} />
          <ListItens label={toMoney(item.ValorFatura)} />
          <ListItens label={toDate(item.DataRef, "MM/YYYY")} />
          <FaturaListIcon>
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              onTap={() => openModalDelete(item.ID, item.Contratante)}
            />

            <BillingIconRounded
              onTap={() => navigate("/faturamento/fatura/" + item.ID)}
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
            />
          </FaturaListIcon>
        </FaturaListItensContainer>
      ))}
      <ModalBilling modalIsOpen={openDeleteModals} width="500px" height="200px">
        <FaturaDeleteContent
          onClose={() => setOpenDeleteModals(false)}
          onDelete={() => deleteFatura()}
          contrato={idNameDelete!}
          onLoading={isLoadingDelete!}
        />
      </ModalBilling>
    </FaturaListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <FaturaListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </FaturaListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <FaturaListItems>
      <Body3 fontWeight={400} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body3>
    </FaturaListItems>
  );
};
export default FaturaList;
