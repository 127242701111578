import { toastError, toastInfo } from "components/toast/billing_toast";
import { KitHttp } from "gateways/repo/kit_http_cliente";
import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { KitModel } from "shared/models/kit_response.model";
import {
  setFilterEnable,
  setFilterKitList,
  setLoadingKit,
  setkitList,
  setkitOptions,
} from "store/kit/kit_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";
import useBillingEnum from "./useBillingEnum";

const UseKit = () => {
  const http = new KitHttp();

  const kit = useSelector((state: RootState) => state.kit);
  const dispatch = useAppDispatch();

  const [isErrorKit, setErrorKit] = useState(false);
  const [tipoKit, setTipoKit] = useState<string>();
  const [tipoCobranca, setTipoCobranca] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [openDeleteModalKit, setDeleteModalKit] = useState<boolean>();
  const [deleteIdKit, setDeleteIdKit] = useState<string | number>();
  const [loadModal, setLoadingModal] = useState(false);
  const { getEnums } = useBillingEnum();
  const getKit = async () => {
    dispatch(setLoadingKit(true));
    setErrorKit(false);

    try {
      const data = await http.getListKit();
      dispatch(setkitList(data.Lista));
      await getEnums();
    } catch (e) {
      setErrorKit(true);
      console.log(e);
    } finally {
      dispatch(setLoadingKit(false));
    }
  };

  const getKitOptions = async () => {
    try {
      const data = await http.getKitOptions();
      const options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });

      dispatch(setkitOptions(options));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoadingKit(false));
    }
  };

  const filterKit = (key: number) => {
    const filter: KitModel[] = [];

    if (kit.enable_filter) {
      kit.filterListKit.forEach(el => filter.push(el));
    } else {
      kit.list.forEach(el => filter.push(el));
    }

    switch (key) {
      case 1:
        filter.sort((a, b) => a.Nome.toLowerCase().localeCompare(b.Nome.toLowerCase()));
        break;
      case 2:
        filter.sort((a, b) =>
          a.TipoCobrancaNome.toLowerCase().localeCompare(b.TipoCobrancaNome.toLowerCase())
        );
        break;

      default:
        filter.sort((a, b) =>
          a.TipoKitNome.toLowerCase().localeCompare(b.TipoKitNome.toLowerCase())
        );
    }

    dispatch(setFilterKitList(filter));
  };

  const filterKitReverse = (key: number) => {
    const filter: KitModel[] = [];

    if (kit.enable_filter) {
      kit.filterListKit.forEach(el => filter.push(el));
    } else {
      kit.list.forEach(el => filter.push(el));
    }

    switch (key) {
      case 1:
        filter.sort((a, b) => b.Nome.toLowerCase().localeCompare(a.Nome.toLowerCase()));
        break;
      case 2:
        filter.sort((a, b) =>
          b.TipoCobrancaNome.toLowerCase().localeCompare(a.TipoCobrancaNome.toLowerCase())
        );
        break;

      default:
        filter.sort((a, b) =>
          b.TipoKitNome.toLowerCase().localeCompare(a.TipoKitNome.toLowerCase())
        );
    }

    dispatch(setFilterKitList(filter));
  };

  const filterResultKit = () => {
    let kits: KitModel[] = kit.list;
    if (kit.type_name_filter) {
      kits = kits.filter(el => el.Nome.toLowerCase().includes(search!.toLowerCase()));
    }
    if (kit.type_kit_filter) {
      kits = kits.filter(el => el.TipoKitNome.toLocaleLowerCase() === tipoKit!.toLocaleLowerCase());
    }

    if (kit.type_cobranca_filter) {
      kits = kits.filter(
        el => el.TipoCobrancaNome.toLocaleLowerCase() === tipoCobranca!.toLocaleLowerCase()
      );
    }

    dispatch(
      setFilterEnable(kit.type_cobranca_filter || kit.type_kit_filter || kit.type_name_filter)
    );
    dispatch(setFilterKitList(kits));
  };

  const deleteKit = async () => {
    setLoadingModal(true);
    try {
      await http.deleteKit(deleteIdKit!);
      toastInfo({ title: "Kit excluido com sucesso." });
      setDeleteModalKit(false);
      getKit();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      setLoadingModal(false);
    }
  };
  const clearFilterKit = () => {
    dispatch(setFilterEnable(false));
    dispatch(setFilterKitList([]));
    setTipoKit("");
    setTipoCobranca("");
    setSearch("");
  };

  return {
    isErrorKit,
    getKit,
    getKitOptions,
    filterKit,
    filterResultKit,
    filterKitReverse,
    setTipoKit,
    setTipoCobranca,
    setSearch,
    clearFilterKit,
    search,
    deleteKit,
    openDeleteModalKit,
    setDeleteModalKit,
    deleteIdKit,
    setDeleteIdKit,
    loadModal,
    setLoadingModal,
  };
};
export default UseKit;
