import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const FaturaContainer = styled.div`
  display: grid;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  grid-template-columns: 187px 1fr;

  .main_content {
    width: 100%;
  }
`;

export const FaturaHeader = styled.div`
  padding: 17.5px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.primary?.A300};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;
export const FaturaFilterContent = styled.div`
  padding: 16px 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
`;
export const FaturaMain = styled.div`
  width: 100%;

  .date {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
`;

export const FatureFilterLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const FaturaFilterButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 32px 12px 12px;
`;
