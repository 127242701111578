import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const NovoContratoKit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    text-align: center;
  }

  .no_content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: solid 0.5px ${billing_theme.pallete.tertiary.A100};
    background: ${billing_theme.pallete.specific.T_25_50};
  }
  .select_content {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
`;
