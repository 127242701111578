// globalStyles.js
import billing_theme from "style/theme_colors";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`



body {
  font-optical-sizing: auto;
  height: 100%;
  margin: 0px;
  background:${billing_theme.pallete.specific.T_25_50};
}

html {
  height: 100%;
}

html,
html * {
  scrollbar-width: thin;
  scrollbar-color: ${billing_theme.pallete.tertiary.A400} transparent;
}

.html::-webkit-scrollbar {
  width: 3px;
  height: 3px; /* A altura só é vista quando a rolagem é horizontal */
}

.html::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
}

.html::-webkit-scrollbar-thumb {
  background-color: #000;
}

.Toastify__toast {
  padding: 0px;
}

.BillingToast {
  padding: 0px !important;
  background:${billing_theme.pallete.tertiary.A25};
}

.BillingToastGeral {
  padding: 0px !important;
  background:${billing_theme.pallete.tertiary.A25}!important;
}


.BillingToastGeral .Toastify__close-button {
  color: ${billing_theme.pallete.primary.A600};
  align-self: flex-start;
  background:${billing_theme.pallete.tertiary.A25};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin: 7px 9px 0px 0px;
}


`;

export default GlobalStyle;
