import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const Body1 = styled.span<{
  color?: string;
  fontWeight?: number | string;
}>`
  color: ${props => props.color ?? billing_theme.pallete.tertiary?.A200};
  font-family: Lato;
  margin: 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? 400};
  line-height: 20px;
`;
export const Body2 = styled.span<{
  color?: string;
  fontWeight?: number | string;
}>`
  color: ${props => props.color ?? billing_theme.pallete.tertiary?.A200};
  font-family: Lato;
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? 400};
  line-height: 20px;
`;
export const Body3 = styled.span<{
  color?: string;
  fontWeight?: number | string;
}>`
  color: ${props => props.color ?? billing_theme.pallete.tertiary?.A200};
  font-family: Lato;
  margin: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? 400};
  line-height: 18px;
`;
export const Body4 = styled.span<{
  color?: string;
  fontWeight?: number | string;
}>`
  color: ${props => props.color ?? billing_theme.pallete.tertiary?.A200};
  font-family: Lato;
  margin: 0px;
  font-size: 12px;
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? 400};
  line-height: 16px;
`;
export const Caution1 = styled.span<{
  color?: string;
  fontWeight?: number | string;
}>`
  color: ${props => props.color ?? billing_theme.pallete.tertiary?.A200};
  margin: 0px;
  font-size: 11px;
  font-style: normal;
  font-weight: ${props => props.fontWeight ?? 400};
  line-height: 15px;
`;
