import { KitContainer } from "./style";
import Scaffold from "components/scaffold";
import KitViewMain from "./components/new_kit_main";
import SkeletonKitList from "./components/kit_list_skeleton";
import useNewKit from "hooks/useNewKit";
import { useEffect } from "react";
import useBillingEnum from "hooks/useBillingEnum";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const NewKitPage: React.FC = () => {
  const { getModelosOptions } = useNewKit();
  const { getEnums } = useBillingEnum();
  const new_kit = useSelector((state: RootState) => state.new_kit);

  useEffect(() => {
    getModelosOptions();
    getEnums();
  }, []);

  return (
    <Scaffold>
      <KitContainer>{new_kit.loading ? <SkeletonKitList /> : <KitViewMain />}</KitContainer>
    </Scaffold>
  );
};

export default NewKitPage;
