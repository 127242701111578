import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { Configuracao } from "shared/models/default_models.model";
import { ServicoModel } from "shared/models/servico_response.model";

export interface ServicoSliceType {
  list: ServicoModel[];
  filterList: ServicoModel[];
  config: Configuracao;
  options: OptionsModel[];
  loading?: boolean;
  isError?: boolean;
}

const initialState: ServicoSliceType = {
  list: [],
  filterList: [],
  options: [],
  config: {
    TotalRegistros: 0,
    PaginaAtual: 0,
    RegistrosPorPagina: 0,
  },
  loading: false,
  isError: false,
};

const servico_slice = createSlice({
  name: "servico",
  initialState,
  reducers: {
    setServicoList(state, action: PayloadAction<ServicoModel[]>) {
      state.list = action.payload;
    },
    setServicoFilterList(state, action: PayloadAction<ServicoModel[]>) {
      state.filterList = action.payload;
    },
    setConfigList(state, action: PayloadAction<Configuracao>) {
      state.config = action.payload;
    },
    setServicoOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setLoadingServicos(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setErrorServicos(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
  },
});

export const {
  setServicoList,
  setConfigList,
  setServicoOptions,
  setServicoFilterList,
  setLoadingServicos,
  setErrorServicos,
} = servico_slice.actions;
export default servico_slice.reducer;
