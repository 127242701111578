import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const InputContent = styled.div<{
  $width?: number;
  $error?: boolean;
}>`
  input {
    border-radius: 4px;
    border: 0.5px solid
      ${props =>
        props.$error ? billing_theme.pallete.error.A100 : billing_theme.pallete.tertiary?.A200};
    font-size: 14px;
    line-height: 27px;
    margin: 0px;
    padding: 6px 0px 6px 12px;
    transition: 300ms;
    color: ${props =>
      props.$error ? billing_theme.pallete.error.A100 : billing_theme.pallete.tertiary?.A400};
    font-weight: 500;
    width: ${props => (props.$width ? props.$width - 12 + "px" : "calc(100% - 12px)")};
    background-color: ${billing_theme.pallete.specific.T_50_25};
  }

  input::placeholder {
    color: ${billing_theme.pallete.tertiary?.A400};
    font-weight: 400;
  }
  input:focus {
    border: 1px solid
      ${props =>
        props.$error ? billing_theme.pallete.error.A100 : billing_theme.pallete.primary?.A100};
    outline: 0px solid ${billing_theme.pallete.primary?.A100};
    box-shadow:
      0px 3px 1px -2px #00000027,
      0px 2px 2px 0px #00000017,
      0px 1px 5px 0px #00000017;
  }
  input:hover {
    box-shadow:
      0px 3px 1px -2px #00000027,
      0px 2px 2px 0px #00000017,
      0px 1px 5px 0px #00000017;
  }
`;
