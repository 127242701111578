import BillingTextInput from "components/input/text_input";
import useServico from "hooks/useServico";
import React from "react";
import { SearchContent, ServicoFilterContent, SearchInputContent } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
const ServicoFilter: React.FC<{}> = () => {
  const { filterByName } = useServico();
  return (
    <ServicoFilterContent>
      <SearchContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Busque pelo nome:
          </Heading6>
          <BillingTextInput
            width={280}
            placeholder="Digite o nome do serviço:"
            change={value => filterByName(value!)}
          />
        </SearchInputContent>
      </SearchContent>
    </ServicoFilterContent>
  );
};

export default ServicoFilter;
