import { BillingHttp } from "gateways/billing_http";
import { OptionsResponseModel } from "shared/models/default_models.model";
import { KitResponseModel } from "shared/models/kit_response.model";
import { Acessorio, KitViewResponseModel } from "shared/models/kit_view_response.model";

export interface UpdateAndPostKitModel {
  ID: number;
  Nome: string;
  ModeloPrincipal_ID: number;
  Descricao?: string;
  TipoKit: number;
  TipoCobranca: number;
  Acessorios?: Acessorio[];
}
export class KitHttp {
  private http = new BillingHttp();

  getListKit = async () => {
    const reponse = await this.http.get<KitResponseModel>("Kit/List");

    return reponse.data;
  };

  getKitOptions = async () => {
    const response = await this.http.get<OptionsResponseModel>("Kit/ListOptions");
    return response.data;
  };
  getKitById = async (id: string) => {
    const response = await this.http.get<KitViewResponseModel>("Kit/Get?id=" + id);
    return response.data;
  };
  addNewkit = async (data: UpdateAndPostKitModel) => {
    await this.http.post("Kit/Insert", { data: data });
  };
  updateKit = async (data: UpdateAndPostKitModel) => {
    await this.http.put("Kit/Update", { data: data });
  };

  deleteKit = async (id: number | string) => {
    await this.http.delete("Kit/Delete?id=" + id);
  };
}
