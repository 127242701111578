import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const TabIndicator = styled.div<{
  tabCount: number;
  duration: number;
  offset: string;
}>`
  position: absolute;
  width: ${props => 100 / props.tabCount}%;
  bottom: 0px;
  left: 0;
  transform: translate(${props => props.offset}, -100%);
  transition: transform ${props => props.duration}ms;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${billing_theme.pallete.primary.A400};
`;

export const Tab = styled.li<{ isFocused: boolean }>`
  flex: 1;
  height: 40px;
  cursor: pointer;
  transition: color 0.3s;
  width: 100%;
  border: none;
`;

export const Tabs = styled.div`
  position: relative;
  list-style: none;
  height: 40px;
  display: flex;
`;

export const OuterSliders = styled.div`
  overflow: hidden;
  width: 98%;
  margin: 0 auto;
`;
export const Sliders = styled.div<{ duration: number; offset: number }>`
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  align-items: center;
  transform: translateX(${props => `${props.offset}%`});
  transition: transform ${props => props.duration}ms;
`;
export const SlideContent = styled.div`
  flex-shrink: 0;
  width: calc(100% - 10px);
  padding: 0px 5px;
`;
export const BillingTabComp = styled.div`
  text-align: center;
  width: 100%;
`;
