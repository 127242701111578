import React from "react";
import { InfoIconContent, InfoTextContent, InfoToastContainer } from "./style";
import { SymbolCodepoints } from "react-material-symbols";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body4 } from "components/text/body/style";

const InfoToastComp: React.FC<{
  icon?: SymbolCodepoints;
  title: string;
  message?: string;
}> = ({ icon, title, message }) => {
  return (
    <InfoToastContainer>
      <InfoIconContent>
        <BillingIconSharp
          icon={icon ?? "info"}
          color={billing_theme.pallete.tertiary.A50}
          size={30}
        />
      </InfoIconContent>
      <InfoTextContent>
        <Heading5 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {title}
        </Heading5>
        {message && <Body4 color={billing_theme.pallete.tertiary.A400}>{message}</Body4>}
      </InfoTextContent>
    </InfoToastContainer>
  );
};

export default InfoToastComp;
