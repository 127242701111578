import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const InputContent = styled.div<{
  $width?: number;
}>`
  .css-13cymwt-control,
  .css-t3ipsp-control {
    border-radius: 4px;
    border: 0.5px solid ${billing_theme.pallete.tertiary?.A200};
    font-size: 14px;
    line-height: 27px;
    margin: 0px;
    padding: 0px;
    transition: 300ms;
    color: ${billing_theme.pallete.tertiary?.A400};
    font-weight: 500;
    width: ${props => (props.$width ? props.$width + "px" : "100%")};
    background-color: ${billing_theme.pallete.specific.T_50_25};
    max-height: 41px;
  }
  .css-t3ipsp-control:hover {
    border: 1px solid ${billing_theme.pallete.primary?.A100};
    box-shadow:
      0px 3px 1px -2px #00000027,
      0px 2px 2px 0px #00000017,
      0px 1px 5px 0px #00000017;
  }
  .css-qbdosj-Input,
  .css-1dimb5e-singleValue {
    color: ${billing_theme.pallete.tertiary?.A400};
  }
  .css-1jqq78o-placeholder {
    color: ${billing_theme.pallete.tertiary?.A400};
    font-weight: 400;
  }
  .css-t3ipsp-control:focus {
    border: 1px solid ${billing_theme.pallete.primary?.A100};
    outline: 0px solid ${billing_theme.pallete.primary?.A100};
    box-shadow:
      0px 3px 1px -2px #00000027,
      0px 2px 2px 0px #00000017,
      0px 1px 5px 0px #00000017;
  }
  input#react-select-2-input {
    color: ${billing_theme.pallete.tertiary?.A400}!important;
  }
`;
