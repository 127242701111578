import styled from "styled-components";

export const NewModeloContent = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  .button {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
`;

export const NewModeloDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
