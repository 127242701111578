import { FuturamentoContainer } from "./style";
import Scaffold from "components/scaffold";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";

const FuturamentoPage: React.FC = () => {
  return (
    <Scaffold>
      <FuturamentoContainer>
        <InsideMenu title={"Faturamento"} icon={"payments"}>
          <InsideMenuButton
            path={"/faturamento/fatura"}
            name={"Fatura"}
            icon={"universal_currency_alt"}
          />
          <InsideMenuButton
            path={"/faturamento/pagamento"}
            name={"Pagamento"}
            icon={"credit_card"}
          />
        </InsideMenu>
      </FuturamentoContainer>
    </Scaffold>
  );
};

export default FuturamentoPage;
