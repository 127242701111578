import { NewKitButton, NewKitContainer, NewKitDetails, NewKitTwoInputs } from "./style";
import { Body2 } from "components/text/body/style";
import { Heading4 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingTextInput from "components/input/text_input";
import BillingSelectInput from "components/input/select";
import NewKitItens from "../new_kit_itens";
import { OptionsModel } from "shared/models/contratada_response.model";
import TertiaryButton from "components/button/tertiaty_button";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "components/button/billing_button";
import {
  resetKitNew,
  setKitDescrição,
  setKitModeloPrincipal,
  setKitNome,
  setKitTipoCobranca,
  setKitTipoKit,
} from "store/kit/new_kit_slice";
import useNewKit from "hooks/useNewKit";
import { ViewKitDetails } from "view/Kit/pages/ViewKIt/components/kit_view_main/style";
import BillingTextArea from "components/input/text_area";

const KitNewMain: React.FC = () => {
  const newKit = useSelector((state: RootState) => state.new_kit);
  const enumList = useSelector((state: RootState) => state.list_enum);
  const { addNewkit } = useNewKit();
  const navegate = useNavigate();
  const dispatch = useAppDispatch();
  const getOptionsModelo = () => {
    const options: OptionsModel[] = [];

    newKit.modelo.forEach(el => {
      options.push({ value: el.ID.toString(), label: el.Text });
    });
    return options;
  };

  return (
    <NewKitContainer>
      <Heading4 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
        Novo Kit
      </Heading4>

      <NewKitTwoInputs>
        <NewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Nome:
          </Body2>
          <BillingTextInput
            change={value => {
              dispatch(setKitNome(value!));
            }}
            placeholder="Qual o nome do kit?"
          />
        </NewKitDetails>

        <NewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Modelo Principal:
          </Body2>
          <BillingSelectInput
            placeholder="Selecione um modelo:"
            options={getOptionsModelo()}
            onChange={(val: any) => {
              val != null
                ? dispatch(setKitModeloPrincipal(val.value))
                : dispatch(setKitModeloPrincipal(undefined));
            }}
          />
        </NewKitDetails>
      </NewKitTwoInputs>

      <NewKitTwoInputs>
        <NewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Tipo Kit:
          </Body2>
          <BillingSelectInput
            placeholder="Selecione um modelo de Kit:"
            options={enumList.TipoKitOptions!}
            onChange={(val: any) => {
              val != null ? dispatch(setKitTipoKit(val.value)) : dispatch(setKitTipoKit(undefined));
            }}
          />
        </NewKitDetails>

        <NewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Tipo Cobrança:
          </Body2>
          <BillingSelectInput
            placeholder="Selecione um modelo de cobrança:"
            options={enumList.CobrancaKitOptions!}
            onChange={(val: any) => {
              val != null
                ? dispatch(setKitTipoCobranca(val.value))
                : dispatch(setKitTipoCobranca(undefined));
            }}
          />
        </NewKitDetails>
      </NewKitTwoInputs>
      <div className="descri">
        <ViewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Descrição
          </Body2>
          <BillingTextArea
            change={value => {
              dispatch(setKitDescrição(value));
            }}
            lines={3}
          />
        </ViewKitDetails>
      </div>
      <NewKitItens />
      <NewKitButton>
        <TertiaryButton
          label={"Voltar"}
          onTap={() => {
            navegate("/cadastro/kit");
            dispatch(resetKitNew());
          }}
          isSmall
        />
        <PrimaryButton
          label={"Salvar"}
          disable={
            newKit.kit.ModeloPrincipal_ID == undefined &&
            newKit.kit.Nome == undefined &&
            newKit.kit.TipoKit == undefined &&
            newKit.kit.TipoCobranca == undefined
          }
          onTap={() => {
            addNewkit({
              ID: 0,
              Nome: newKit.kit.Nome!,
              ModeloPrincipal_ID: newKit.kit.ModeloPrincipal_ID!,
              Descricao: newKit.kit.Descricao,
              TipoKit: Number(newKit.kit.TipoKit!),
              TipoCobranca: Number(newKit.kit.TipoCobranca!),
              Acessorios: newKit.kit.Acessorios,
            });
          }}
          isSmall
        />
      </NewKitButton>
    </NewKitContainer>
  );
};

export default KitNewMain;
