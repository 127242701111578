import React from "react";
import { Heading5, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { NovoContratoInputLabel, NovoContratoPrazosDesconto } from "./style";
import BillingNumberInput from "components/input/number_input";
import { useAppDispatch } from "store/store";
import { setMesesState, setPorcentagemState } from "store/contrato/new_contrato_slice";

const NovoContratoDescontoComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <NovoContratoPrazosDesconto>
      <div className="desconto">
        <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A600}>
          Desconto
        </Heading5>
      </div>

      <div className="desconto">
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Porcentagem
          </Heading6>
          <BillingNumberInput
            placeholder="%"
            onNumberFormat={val => {
              dispatch(setPorcentagemState(val.number));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Meses
          </Heading6>
          <BillingNumberInput
            placeholder="Quantos Meses?"
            onNumberFormat={val => {
              dispatch(setMesesState(val.number));
            }}
          />
        </NovoContratoInputLabel>
      </div>
    </NovoContratoPrazosDesconto>
  );
};
export default NovoContratoDescontoComponent;
