import styled from "styled-components";

export const KitFilterContent = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 12px;
`;
export const KitFilterLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
export const KitFilterButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 32px 12px 12px;
`;
