import BillingTextInput from "components/input/text_input";
import useModelo from "hooks/useModelo";
import React from "react";
import { SearchContent, ModeloFilterContent, SearchInputContent } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
const ModeloFilter: React.FC<{}> = () => {
  const { filterByName } = useModelo();
  return (
    <ModeloFilterContent>
      <SearchContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Busque pelo nome:
          </Heading6>
          <BillingTextInput
            width={280}
            placeholder="Digite o nome de um modelo:"
            change={value => filterByName(value!)}
          />
        </SearchInputContent>
      </SearchContent>
    </ModeloFilterContent>
  );
};

export default ModeloFilter;
