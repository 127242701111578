import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const CheckboxContent = styled.label`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 0px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: relative;
    display: flex;
    gap: 8px;
    width: 15px;
    height: 15px;
    background-color: ${billing_theme.pallete.tertiary.A50};
    border: 0.5px solid ${billing_theme.pallete.tertiary.A300};
    border-radius: 4px;
  }

  .checked {
    background-color: ${billing_theme.pallete.primary.A400};
    border: 1px solid ${billing_theme.pallete.primary.A400};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 8px;
    border: 1px solid ${billing_theme.pallete.tertiary.A300};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:checked + .checkmark:after {
    display: block;
  }
`;
