import React from "react";
import Modal from "react-modal";
import billing_theme from "style/theme_colors";

const ModalBilling: React.FC<{
  children?: React.ReactNode;
  modalIsOpen: boolean;
  width?: string;
  height?: string;
}> = ({ children, modalIsOpen, width, height }) => {
  const customStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      padding: "24px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: billing_theme.pallete.tertiary.A25,
      height: height,
      width: width,
    },
    overlay: {
      backgroundColor: billing_theme.pallete.overlay,
    },
  };

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      {children}
    </Modal>
  );
};

export default ModalBilling;
