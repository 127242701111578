import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FaturaItemModel } from "shared/models/fatura_list_response.model";
import { Configuracao } from "shared/models/default_models.model";

export interface FaturaSliceType {
  list: FaturaItemModel[];
  filterList: FaturaItemModel[];
  config: Configuracao;
  search_fatura_filter: boolean;
  date_filter: boolean;
  valor_menor_fatura_filter: boolean;
  valor_maior_fatura_filter: boolean;
  redorder_fatura_filter: boolean;
  enable_filter: boolean;
  openNovaFaturalModal: boolean;
  loading?: boolean;
  isError?: boolean;
}

const initialState: FaturaSliceType = {
  list: [],
  filterList: [],

  search_fatura_filter: false,
  date_filter: false,
  valor_menor_fatura_filter: false,
  valor_maior_fatura_filter: false,
  redorder_fatura_filter: false,
  enable_filter: false,
  config: {
    TotalRegistros: 0,
    PaginaAtual: 0,
    RegistrosPorPagina: 0,
  },
  openNovaFaturalModal: false,
  loading: false,
  isError: false,
};

const fatura_slice = createSlice({
  name: "fatura",
  initialState,
  reducers: {
    setFaturaList(state, action: PayloadAction<FaturaItemModel[]>) {
      state.list = action.payload;
    },
    setFaturaFilterList(state, action: PayloadAction<FaturaItemModel[]>) {
      state.filterList = action.payload;
    },
    setConfigList(state, action: PayloadAction<Configuracao>) {
      state.config = action.payload;
    },
    setFilterbyName(state, action: PayloadAction<boolean>) {
      state.search_fatura_filter = action.payload;
    },
    setFilterByDate(state, action: PayloadAction<boolean>) {
      state.date_filter = action.payload;
    },

    setFilterByValorMenor(state, action: PayloadAction<boolean>) {
      state.valor_menor_fatura_filter = action.payload;
    },
    setFilterByValorMaior(state, action: PayloadAction<boolean>) {
      state.valor_maior_fatura_filter = action.payload;
    },
    setFilterByReorder(state, action: PayloadAction<boolean>) {
      state.redorder_fatura_filter = action.payload;
    },
    setFilterEnable(state, action: PayloadAction<boolean>) {
      state.enable_filter = action.payload;
    },
    setNovaFaturalModalState(state, action: PayloadAction<boolean>) {
      state.openNovaFaturalModal = action.payload;
    },
    setLoadingFatura(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setErrorFatura(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
  },
});

export const {
  setFaturaList,
  setConfigList,
  setFaturaFilterList,
  setFilterByReorder,
  setFilterByValorMaior,
  setFilterByValorMenor,
  setFilterbyName,
  setFilterByDate,
  setFilterEnable,
  setNovaFaturalModalState,
  setLoadingFatura,
  setErrorFatura,
} = fatura_slice.actions;
export default fatura_slice.reducer;
