import React from "react";
import { BillingTabComp, OuterSliders, Sliders, Tab, TabIndicator, Tabs } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";

export const SlidersComp: React.FC<{
  focusedIdx: number;
  duration?: number;
  children?: React.ReactNode;
}> = ({ focusedIdx, duration = 300, children }) => {
  const offset = -100 * focusedIdx;

  return (
    <OuterSliders>
      <Sliders offset={offset} duration={duration}>
        {children}
      </Sliders>
    </OuterSliders>
  );
};

const TabsComp: React.FC<{
  focusedIdx: number;
  duration?: number;
  children?: React.ReactNode[];
}> = ({ focusedIdx, duration = 300, children }) => {
  return (
    <Tabs>
      {children}
      <TabIndicator
        duration={duration}
        tabCount={children!.length}
        offset={`${100 * focusedIdx}%`}
      />
    </Tabs>
  );
};

const TabComp: React.FC<{
  title: string;
  onClick: () => void;
  isFocused: boolean;
}> = ({ title, onClick, isFocused }) => {
  return (
    <Tab onClick={onClick} isFocused={isFocused}>
      <Heading6
        fontWeight={isFocused ? 700 : 500}
        color={isFocused ? billing_theme.pallete.primary.A400 : billing_theme.pallete.tertiary.A400}
      >
        {title}
      </Heading6>
    </Tab>
  );
};

const BillingTab: React.FC<{
  titles: string[];
  children: React.ReactNode[];
}> = ({ titles, children }) => {
  const [focusedIdx, setFocusedIdx] = React.useState(0);

  return (
    <BillingTabComp>
      <TabsComp focusedIdx={focusedIdx}>
        {titles.map((value, index) => {
          return (
            <TabComp
              title={value}
              onClick={() => {
                setFocusedIdx(index);
              }}
              isFocused={focusedIdx === index}
            />
          );
        })}
      </TabsComp>
      <SlidersComp focusedIdx={focusedIdx}>{children}</SlidersComp>
    </BillingTabComp>
  );
};
export default BillingTab;
