import { useEffect, useState } from "react";
import {
  ModeloListButton,
  ModeloListContent,
  ModeloListHeader,
  ModeloListIcon,
  ModeloListItems,
  ModeloListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import useModelo from "hooks/useModelo";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import ModalBilling from "components/modal";
import ModalViewModelo from "view/Modelo/page/ViewModelo";
import { ModeloResponseList } from "shared/models/modelo_list_response.model";
import ModeloDeleteContent from "../modelo_delete_modal";

const ModeloList: React.FC<{}> = () => {
  const modelo = useSelector((state: RootState) => state.modelo);
  const {
    filterModelo,
    filterModeloReverse,
    setDeleteModalModelo,
    setDeleteIdModelo,
    deleteModelo,
    openDeleteModalModelo,
    loadModal,
    deleteIdModelo,
  } = useModelo();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<ModeloResponseList[]>([]);
  const [filterState, setFilterState] = useState("normal");
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  const selectFilter = (id: string, index: number) => {
    if (filter != id) setFilterState("normal");

    if (filterState == "normal") {
      filterModelo(index);
      setFilterState("reverse");
    } else {
      filterModeloReverse(index);
      setFilterState("normal");
    }
    setFilter(id);
  };

  useEffect(() => {
    modelo.filter!.length == 0 ? setData(modelo.list!) : setData(modelo.filter!);
  });
  return (
    <ModeloListContent>
      <ModeloListHeader>
        <ListButton
          label="Nome"
          filterState={filterState}
          click={() => selectFilter("Nome", 1)}
          selectedFilter={filter}
        />
        <ListButton
          label="Tipo de Equipamento"
          filterState={filterState}
          click={() => selectFilter("Tipo de Equipamento", 2)}
          selectedFilter={filter}
        />

        <ListButton
          label="Opções"
          filterState={filterState}
          click={() => console.log("oi")}
          selectedFilter={"options"}
        />
      </ModeloListHeader>
      {data.map(item => (
        <ModeloListItensContainer>
          <ListItens label={item.Nome} />
          <ListItens label={item.Tipo} />

          <ModeloListIcon>
            <BillingIconRounded
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={() => {
                setSelectedId(item.ID);
                setOpenViewModal(true);
              }}
            />
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              onTap={() => {
                setDeleteIdModelo(item.ID);
                setDeleteModalModelo(true);
              }}
            />
          </ModeloListIcon>
        </ModeloListItensContainer>
      ))}
      <ModalBilling modalIsOpen={openDeleteModalModelo!} width="500px" height="200px">
        <ModeloDeleteContent
          onClose={() => setDeleteModalModelo(false)}
          onDelete={() => deleteModelo()}
          onLoading={loadModal!}
        />
      </ModalBilling>
      <ModalBilling modalIsOpen={openViewModal} width="450px" height="400px">
        <ModalViewModelo
          id={selectedId!}
          onClose={() => {
            setOpenViewModal(false);
          }}
        />
      </ModalBilling>
    </ModeloListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <ModeloListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </ModeloListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <ModeloListItems>
      <Body3 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body3>
    </ModeloListItems>
  );
};
export default ModeloList;
