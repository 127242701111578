import { OptionsModel } from "shared/models/contratada_response.model";
import { EnumModel } from "shared/models/list_enum.model";

export const formatOptionList = (list: EnumModel[]) => {
  const listOptions: OptionsModel[] = [];
  list.forEach(el => {
    listOptions.push({ value: el.ID.toString(), label: el.Text });
  });
  return listOptions;
};
export const formatToOption = (id: string, name: string) => {
  return { value: id, label: name };
};
