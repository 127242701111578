import { NovoContratoContent, NovoContratoForm, NovoContratoHeader } from "./style";
import React, { useEffect, useState } from "react";
import Scaffold from "components/scaffold";
import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import PrimaryButton from "components/button/billing_button";
import BillingSideBar from "components/sidebar";
import NovoContratoContracts from "./components/contratos";
import NovoContratoPrazosComponent from "./components/prazos";
import NovoContratoConfiguracaoComp from "./components/configuracao";
import NovoContratoKitComp from "./components/kit";
import NovoContratoAddKit from "./components/kit/components/insert_kit";

import NovoContratoServicos from "./components/servicos";
import TertiaryButton from "components/button/tertiaty_button";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "store/store";
import { resetNovoContratoState, setActualPage } from "store/contrato/new_contrato_slice";
import NovoContratoSkeleton from "./components/novo_contrato_skeleton";
import NovoCotratoKitPrincipal from "./components/kit_principal";
import NovoContratoDescontoComponent from "./components/desconto";
import useNovoContrato from "hooks/useNovoContrato";
import { useSelector } from "react-redux";
import { SlidersComp } from "components/tab_slider";
import { SlideContent } from "components/tab_slider/style";

const NovoContratoPage: React.FC = () => {
  const [openKit, setSetOpenKit] = useState<boolean>(false);

  const { addNewContrato, isLoadingNovoContrato, loadContatoData } = useNovoContrato();
  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  useEffect(() => {
    loadContatoData();
  }, []);

  return (
    <>
      <Scaffold>
        <NovoContratoContent>
          <NovoContratoHeader>
            <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A600}>
              Novo contrato
            </Heading3>
            <Body2 color={billing_theme.pallete.tertiary?.A600}>
              Preencha os campos com atenção.
            </Body2>
          </NovoContratoHeader>
          <div className="contain">
            <NovoContratoForm>
              {isLoadingNovoContrato ? (
                <NovoContratoSkeleton />
              ) : (
                <SlidersComp focusedIdx={novo_contrato.pageIndex}>
                  {
                    <>
                      <FirstSlide />
                      <SecondSlide />
                      <ThirdSlide addNewContrato={addNewContrato} />
                    </>
                  }
                </SlidersComp>
              )}
            </NovoContratoForm>
          </div>
        </NovoContratoContent>
      </Scaffold>
      <BillingSideBar open={openKit}>
        <NovoContratoAddKit openModal={() => setSetOpenKit(false)} />
      </BillingSideBar>
    </>
  );
};

const FirstSlide: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);

  return (
    <SlideContent>
      <div className="content">
        <NovoContratoContracts />
        <NovoCotratoKitPrincipal />
        <div className="buttons">
          <TertiaryButton
            label={"Cancelar"}
            onTap={() => {
              dispatch(resetNovoContratoState());
              navigate("/contrato");
            }}
          />
          <PrimaryButton
            label={"Prosseguir"}
            disable={
              novo_contrato.contratante == undefined ||
              novo_contrato.contratada == undefined ||
              novo_contrato.kitPrincipal == undefined ||
              novo_contrato.quantidade == undefined ||
              novo_contrato.mensalidade == undefined ||
              novo_contrato.instalacao == undefined ||
              novo_contrato.desinstalacao == undefined ||
              novo_contrato.transferencia == undefined
            }
            onTap={() => {
              dispatch(setActualPage(1));
            }}
          />
        </div>
      </div>
    </SlideContent>
  );
};

const SecondSlide: React.FC = () => {
  const dispatch = useAppDispatch();

  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);

  return (
    <SlideContent>
      <div className="content">
        <NovoContratoPrazosComponent />
        <NovoContratoDescontoComponent />
        <NovoContratoConfiguracaoComp />
        <div className="buttons">
          <TertiaryButton
            label={"Voltar"}
            onTap={() => {
              dispatch(setActualPage(0));
            }}
          />
          <PrimaryButton
            label={"Prosseguir"}
            disable={
              novo_contrato.duracao == undefined ||
              novo_contrato.carencia == undefined ||
              novo_contrato.fidelidade == undefined ||
              novo_contrato.porcentagem == undefined ||
              novo_contrato.meses == undefined ||
              novo_contrato.indice == undefined ||
              novo_contrato.valorReajustavel == undefined ||
              novo_contrato.valorNaoReajustavel == undefined
            }
            onTap={() => {
              dispatch(setActualPage(2));
            }}
          />
        </div>
      </div>
    </SlideContent>
  );
};

const ThirdSlide: React.FC<{ addNewContrato: () => void }> = ({ addNewContrato }) => {
  const dispatch = useAppDispatch();

  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);

  return (
    <SlideContent>
      <div className="content">
        <NovoContratoKitComp />
        <NovoContratoServicos />
        <div className="buttons">
          <TertiaryButton
            label={"Voltar"}
            onTap={() => {
              dispatch(setActualPage(1));
            }}
          />
          <PrimaryButton
            label={"Salvar"}
            disable={
              novo_contrato.kitsComplementares.length == 0 || novo_contrato.servico.length == 0
            }
            onTap={() => {
              addNewContrato();
            }}
          />
        </div>
      </div>
    </SlideContent>
  );
};
export default NovoContratoPage;
