import React, { useState } from "react";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { NovoContratoServico } from "./style";
import TableComponentKit from "./components/table";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import TertiaryButton from "components/button/tertiaty_button";
import Select, { SingleValue } from "react-select";
import { OptionsModel } from "shared/models/contratada_response.model";
import { addServicoState } from "store/contrato/new_contrato_slice";
import BillingSelectInput from "components/input/select";
import { ServicoNewContrato } from "shared/models/contrato_post.model";

const NovoContratoServicos: React.FC = () => {
  const data = useSelector((state: RootState) => state.novo_contrato);
  const servico = useSelector((state: RootState) => state.servico);
  const dispatch = useAppDispatch();

  const [servicoId, setServicoId] = useState<string>();

  const addServico = () => {
    const servicoScope: ServicoNewContrato = {
      ID: 0,
      Servico_ID: Number(servicoId),
    };
    dispatch(addServicoState(servicoScope));
  };
  return (
    <NovoContratoServico>
      <div className="header">
        <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A600}>
          Seleção de serviços
        </Heading5>
      </div>
      <div className="select_content">
        <BillingSelectInput
          onChange={(val: SingleValue<OptionsModel>) => {
            setServicoId(val?.value!);
          }}
          options={servico.options}
          placeholder={"Selecione um  Contrato Inicial"}
        />
        <TertiaryButton label={"Adicionar"} isSmall onTap={() => addServico()} />
      </div>
      {data.servico.length == 0 ? (
        <div className="no_content">
          <Heading5 fontWeight={700} color={billing_theme.pallete.tertiary.A400}>
            Você ainda não selecionou nenhum serviço
          </Heading5>
        </div>
      ) : (
        <TableComponentKit servicos={data.servico} />
      )}
    </NovoContratoServico>
  );
};
export default NovoContratoServicos;
