import {
  ContratoContainer,
  ContratoFilterContent,
  ContratoHeader,
  DataContent,
  SearchContent,
} from "./style";
import { Heading3, Heading6 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect } from "react";
import ContrantoList from "./components/contrato_list";
import useContrato from "hooks/useContrato";
import InsideMenu from "components/insidemenu";
import { useNavigate } from "react-router-dom";
import BillingTextInput from "components/input/text_input";
import SkeletonContratoList from "./components/contrato_skeleton";
import ContratoErrorContent from "./components/contrato_list_error";
import { FatureFilterLabel } from "view/Fatura/style";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const ContratoPage: React.FC = () => {
  const navigate = useNavigate();
  const contrato = useSelector((state: RootState) => state.contrato);

  const { getContratos, isListError } = useContrato();

  useEffect(() => {
    getContratos();
  }, []);

  return (
    <Scaffold>
      <ContratoContainer>
        <InsideMenu title={"Contrato"} icon={"description"}></InsideMenu>
        <div className="main_content">
          <ContratoHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Contratos
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>
                Gestão de todos os contratos na plataforma.
              </Body2>
            </div>

            <PrimaryButton
              label={"Novo contrato"}
              leftIcon={"add"}
              isSmall
              onTap={() => navigate("/contrato/novo_contrato")}
            />
          </ContratoHeader>
          {isListError && <ContratoErrorContent retry={() => getContratos()} />}

          {!isListError && (
            <ContratoFilterContent>
              <FatureFilterLabel>
                <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
                  Nome do contratante:
                </Heading6>
                <BillingTextInput
                  width={280}
                  placeholder="O que deseja procurar:"
                  change={value => {}}
                />
              </FatureFilterLabel>
            </ContratoFilterContent>
          )}
          {!isListError && (contrato.loading ? <SkeletonContratoList /> : <ContrantoList />)}
        </div>
      </ContratoContainer>
    </Scaffold>
  );
};

export default ContratoPage;
