import { NovoContratoContent, NovoContratoForm, NovoContratoHeader } from "./style";
import React, { useEffect, useState } from "react";
import Scaffold from "components/scaffold";
import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import PrimaryButton from "components/button/billing_button";
import BillingSideBar from "components/sidebar";
import NovoContratoContracts from "./components/contratos";
import NovoContratoPrazosComponent from "./components/prazos";
import NovoContratoConfiguracaoComp from "./components/configuracao";
import NovoContratoKitComp from "./components/kit";
import NovoContratoAddKit from "./components/kit/components/insert_kit";
import NovoContratoServicos from "./components/servicos";
import TertiaryButton from "components/button/tertiaty_button";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "store/store";
import NovoCotratoKitPrincipal from "./components/kit_principal";
import NovoContratoDescontoComponent from "./components/desconto";
import { useSelector } from "react-redux";
import { SlidersComp } from "components/tab_slider";
import { SlideContent } from "components/tab_slider/style";
import useEditContrato from "hooks/useEditContrato";
import NovoContratoSkeleton from "./components/novo_contrato_skeleton";
import { resetViewContratoState, setActualPage } from "store/contrato/view_contrato_slice";

const ViewContratoPage: React.FC = () => {
  const [openKit, setSetOpenKit] = useState<boolean>(false);

  const { getViewKit, updateContrato } = useEditContrato();

  const view_contrato = useSelector((state: RootState) => state.view_contrato);
  useEffect(() => {
    getViewKit();
  }, []);

  return (
    <>
      <Scaffold>
        <NovoContratoContent>
          <NovoContratoHeader>
            <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A600}>
              Novo contrato
            </Heading3>
            <Body2 color={billing_theme.pallete.tertiary?.A600}>
              Preencha os campos com atenção.
            </Body2>
          </NovoContratoHeader>
          <div className="contain">
            <NovoContratoForm>
              {view_contrato.loading ? (
                <NovoContratoSkeleton />
              ) : (
                <SlidersComp focusedIdx={view_contrato.pageIndex!}>
                  {
                    <>
                      <FirstSlide />
                      <SecondSlide />
                      <ThirdSlide updateContrato={updateContrato} />
                    </>
                  }
                </SlidersComp>
              )}
            </NovoContratoForm>
          </div>
        </NovoContratoContent>
      </Scaffold>
      <BillingSideBar open={openKit}>
        <NovoContratoAddKit openModal={() => setSetOpenKit(false)} />
      </BillingSideBar>
    </>
  );
};

const FirstSlide: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const view_contrato = useSelector((state: RootState) => state.view_contrato);

  return (
    <SlideContent>
      <div className="content">
        <NovoContratoContracts />
        <NovoCotratoKitPrincipal />
        <div className="buttons">
          <TertiaryButton
            label={"Cancelar"}
            onTap={() => {
              dispatch(resetViewContratoState());
              navigate("/contrato");
            }}
          />
          <PrimaryButton
            label={"Prosseguir"}
            disable={
              view_contrato.Contratante_ID == undefined ||
              view_contrato.Contratada_ID == undefined ||
              view_contrato.KitPrincipal_ID == undefined ||
              view_contrato.Quantidade == undefined ||
              view_contrato.Mensalidade == undefined ||
              view_contrato.Instalacao == undefined ||
              view_contrato.Desinstalacao == undefined ||
              view_contrato.Transferencia == undefined
            }
            onTap={() => {
              dispatch(setActualPage(1));
            }}
          />
        </div>
      </div>
    </SlideContent>
  );
};

const SecondSlide: React.FC = () => {
  const dispatch = useAppDispatch();

  const view_contrato = useSelector((state: RootState) => state.view_contrato);

  return (
    <SlideContent>
      <div className="content">
        <NovoContratoPrazosComponent />
        <NovoContratoDescontoComponent />
        <NovoContratoConfiguracaoComp />
        <div className="buttons">
          <TertiaryButton
            label={"Voltar"}
            onTap={() => {
              dispatch(setActualPage(0));
            }}
          />
          <PrimaryButton
            label={"Prosseguir"}
            disable={
              view_contrato.DuracaoMeses == undefined ||
              view_contrato.CarenciaDias == undefined ||
              view_contrato.FidelidadeMeses == undefined ||
              view_contrato.PercentualDesconto == undefined ||
              view_contrato.MesesDesconto == undefined ||
              view_contrato.Indice == undefined ||
              view_contrato.ValorReajustavel == undefined ||
              view_contrato.ValorNaoReajustavel == undefined
            }
            onTap={() => {
              dispatch(setActualPage(2));
            }}
          />
        </div>
      </div>
    </SlideContent>
  );
};

const ThirdSlide: React.FC<{ updateContrato: () => void }> = ({ updateContrato }) => {
  const dispatch = useAppDispatch();

  const view_contrato = useSelector((state: RootState) => state.view_contrato);

  return (
    <SlideContent>
      <div className="content">
        <NovoContratoKitComp />
        <NovoContratoServicos />
        <div className="buttons">
          <TertiaryButton
            label={"Voltar"}
            onTap={() => {
              dispatch(setActualPage(1));
            }}
          />
          <PrimaryButton
            label={"Salvar"}
            disable={
              view_contrato.KitsComplementares!.length == 0 || view_contrato.Servicos!.length == 0
            }
            onTap={() => {
              updateContrato();
            }}
          />
        </div>
      </div>
    </SlideContent>
  );
};
export default ViewContratoPage;
