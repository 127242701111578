import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Lista } from "shared/models/default_models.model";
import { Acessorio, KitViewResponseModel } from "shared/models/kit_view_response.model";

interface KitViewState {
  kit: {
    ID: number;
    Nome: string;
    ModeloPrincipal_ID: number;
    Descricao: string;
    TipoKit: number;
    TipoCobranca: number;

    Acessorios: Acessorio[];
  };
  modelo: Lista[];
  loading: boolean;
}

const initialState: KitViewState = {
  kit: {
    ID: 0,
    Nome: "",
    ModeloPrincipal_ID: 0,
    Descricao: "",
    TipoKit: 0,
    TipoCobranca: 0,
    Acessorios: [],
  },
  modelo: [],
  loading: true,
};

const kit_view_slice = createSlice({
  name: "kitViewSlice",
  initialState,
  reducers: {
    resetKitNew: () => initialState,
    setKitView(state, action: PayloadAction<KitViewResponseModel>) {
      state.kit = action.payload.Objeto;
    },

    setKitModelos(state, action: PayloadAction<Lista[]>) {
      state.modelo = action.payload;
    },
    setKitAcessorios(state, action: PayloadAction<Acessorio[]>) {
      state.kit.Acessorios = action.payload;
    },
    setKitLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setKitView, setKitModelos, setKitAcessorios, resetKitNew, setKitLoading } =
  kit_view_slice.actions;
export default kit_view_slice.reducer;
