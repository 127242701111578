import React from "react";
import { SideBarOverview, SidebarContent } from "./style";

const BillingSideBar: React.FC<{
  open: boolean;
  children?: React.ReactNode;
}> = ({ children, open }) => {
  return (
    <SideBarOverview open={open}>
      <SidebarContent>{children}</SidebarContent>
    </SideBarOverview>
  );
};

export default BillingSideBar;
