import { toastError, toastInfo } from "components/toast/billing_toast";
import { ContratoHttp } from "gateways/repo/contrato_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ContratoModelLit } from "shared/models/contratos_response.model";
import {
  setContratosList,
  setFilterList,
  setLoadingContrato,
  setOptions,
} from "store/contrato/contrato_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useContrato = () => {
  const contrato = useSelector((state: RootState) => state.contrato);
  const http = new ContratoHttp();
  const dispatch = useAppDispatch();

  const [isListError, setListError] = useState(false);
  const [list, setList] = useState<ContratoModelLit[]>([]);
  const [filterList, setFilterLists] = useState<ContratoModelLit[]>([]);
  const [deleteContratoModal, setDeleteContratoModal] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [deleteIdContrato, setDeleteIddeleteIdContrato] = useState<string>();

  const getContratos = async () => {
    dispatch(setLoadingContrato(true));
    setListError(false);
    try {
      const data = await http.getListContrato();
      setList(data.Lista);
      dispatch(setContratosList(data.Lista));
    } catch (e) {
      setListError(true);
      toastError({ title: "Erro", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setLoadingContrato(false));
    }
  };

  const getContratoOptions = async () => {
    setListError(false);
    try {
      const data = await http.getListContratoOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });
      dispatch(setOptions(options));
    } catch (e) {
      setListError(true);
      toastError({ title: "Erro", message: "Por favor, tente novamente!" });
    }
  };

  const filterContrato = (key: number) => {
    const filter: ContratoModelLit[] = [];

    contrato.list.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => a.ID - b.ID);
        break;
      case 2:
        filter.sort((a, b) => Number(a.Numero) - Number(b.Numero));
        break;
      case 3:
        filter.sort((a, b) => a.Aditivo - b.Aditivo);
        break;
      case 4:
        filter.sort((a, b) =>
          a.Contratante.toLowerCase().localeCompare(b.Contratante.toLowerCase())
        );
        break;
      default:
        filter.sort((a, b) => a.Contratada.toLowerCase().localeCompare(b.Contratada.toLowerCase()));
    }

    dispatch(setFilterList(filter));
    setFilterLists(filter);
  };

  const filterContratoReverse = (key: number) => {
    const filter: ContratoModelLit[] = [];

    contrato.list.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => b.ID - a.ID);
        break;
      case 2:
        filter.sort((a, b) => Number(b.Numero) - Number(a.Numero));
        break;
      case 3:
        filter.sort((a, b) => b.Aditivo - a.Aditivo);
        break;
      case 4:
        filter.sort((a, b) =>
          b.Contratante.toLowerCase().localeCompare(a.Contratante.toLowerCase())
        );
        break;
      default:
        filter.sort((a, b) => b.Contratada.toLowerCase().localeCompare(a.Contratada.toLowerCase()));
        break;
    }

    dispatch(setFilterList(filter));
    setFilterLists(filter);
  };

  const deleteModal = async () => {
    setIsLoadingDelete(true);
    try {
      await http.deleteContrato(deleteIdContrato!);
      toastInfo({ title: "Kit excluido com sucesso." });
      setDeleteContratoModal(false);
      getContratos();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      setIsLoadingDelete(false);
    }
  };
  return {
    list,
    filterList,
    isListError,
    getContratos,
    getContratoOptions,
    filterContrato,
    filterContratoReverse,
    deleteContratoModal,
    setDeleteContratoModal,
    isLoadingDelete,
    deleteModal,
    setDeleteIddeleteIdContrato,
  };
};
export default useContrato;
