import * as CNPJ from "@fnando/cnpj";
import * as CPF from "@fnando/cpf";
import moment, { Moment } from "moment";
import "moment/min/locales";
import { onlyNumbers } from "./numberUtils";

moment.locale("pt-br");

export const firstLetterUpCase = (phrase: string): string => {
  if (!phrase) return "";
  return phrase.charAt(0).toUpperCase() + phrase.substring(1)?.toLowerCase();
};

export const upperCaseAll = (phrase: string): string => {
  if (!phrase) return "";
  return phrase.toUpperCase();
};

export const abbrName = (name?: string): string => {
  const names = (name ?? "").split(" ");
  return (
    firstLetterUpCase(names?.shift() as string).charAt(0) +
    firstLetterUpCase(names?.pop() as string).charAt(0)
  );
};

export const capitalizeAll = (phrase?: string): string => {
  return (phrase ?? "")
    .split(" ")
    .map((str: string) => {
      return firstLetterUpCase(str.toLowerCase());
    })
    .join(" ");
};

export const firstName = (fullName: string) =>
  capitalizeAll(
    fullName
      ?.split(" ")
      ?.filter(entry => entry.trim() !== "")
      ?.shift()
  );

export const formattedTel = (tel?: string): string => {
  const cleanTel = (tel ?? "").replace(/\D/g, "");
  const substring = cleanTel.length > 10 ? 7 : 6;

  return `(${cleanTel.substring(0, 2)}) ${cleanTel.substring(
    2,
    substring
  )}-${cleanTel.substring(substring, cleanTel.length)}`;
};

export const toDate = (date: Date | Moment | string, format: string = "DD/MM/YYYY") =>
  moment(date, [
    "DD/MM/YYYY HH:mm:ss",
    "HH:mm:ss",
    "YYYY-MM-DD[T]HH:mm:ss",
    "YYYY-MM-DD",
    "DD/MM/YYYY",
  ]).format(format);

export const toShortText = (text: string, maxLength: number): any => {
  if (!text) return "";
  if (text.length >= maxLength) {
    const str = text.substr(0, maxLength);
    return `${str.padEnd(text.length, " ")}...`;
  }
  return text;
};

export const formatCpf = (cpf: string) => CPF.format(cpf);
export const formatCNPJ = (cnpj: string) => CNPJ.format(cnpj);

export const removeSpecialCharacters = (value?: string): string =>
  value?.replace(/[^a-zA-Z0-9]/g, "") ?? "";

export const onlyNumbersAndLetters = (value: string) => value.replace(/[^a-zA-Z0-9]/g, "");

export const replaceSpecialChars = (value: string) =>
  value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const isCPF = (cpfOrCnpj: string) => cpfOrCnpj?.replace(/[^\w\s]/gi, "").length === 11;
export const isCnpj = (cpfOrCnpj: string) => cpfOrCnpj?.replace(/[^\w\s]/gi, "").length === 14;

export const formatKeyMessage = (chave: string, tipo: string) => {
  switch (tipo) {
    case "PHONE":
      return `Celular: ${formmatKeyPhone(chave)}`;

    case "EVP":
      return `Chave Aleatória: ${chave}`;

    case "EMAIL":
      return `E-Mail: ${chave}`;

    case "CPF":
      return `CPF: ${formatCpf(chave)}`;

    case "CNPJ":
      return `CNPJ: ${formatCNPJ(chave)}`;

    default:
      return `${tipo} : ${chave}`;
  }
};

function formmatKeyPhone(pixKey: string): string {
  const substring = pixKey.length > 10 ? 7 : 6;
  pixKey = pixKey.replace("+55", "");
  pixKey = onlyNumbers(pixKey);
  let keyformat = `+55 (${pixKey.substring(0, 2)})${pixKey.substring(
    2,
    substring
  )}-${pixKey.substring(substring, pixKey.length)}`;
  return keyformat;
}

export const formatKey = (key: string) => {
  if (isCPF(key)) return formatCpf(key);
  if (isCnpj(key)) return formatCNPJ(key);

  return key;
};

export const maskToCpf = (cpf: string) => {
  const split = cpf.split("");
  let result = "";
  for (let i = 0; i <= split.length - 1; i++) {
    [0, 1, 2, 9, 10].includes(i) ? (result += "*") : (result += split[i]);
  }
  return result;
};

export const maskToCpfCnpj = (CPFCNPJ: string) => {
  let cpfCnpjNumbers = removeSpecialCharacters(CPFCNPJ);
  let cpfCnpjLength = cpfCnpjNumbers.length;

  if (cpfCnpjLength > 11) {
    let cnpjFormatted = `*****.${cpfCnpjNumbers.substring(5, 8)}/${cpfCnpjNumbers.substring(8, 12)}-**`;
    return cnpjFormatted;
  } else {
    let cpfFormatted = `***.${cpfCnpjNumbers.substring(3, 6)}.${cpfCnpjNumbers.substring(6, 9)}-**`;
    return cpfFormatted;
  }
};

export const formatCPForCNPJ = (value: string) => {
  let result;
  value.length === 11 ? (result = formatCpf(value)) : (result = formatCNPJ(value));
  return result;
};

export const formatAccount = (Valor: string) => {
  let accountLength = Valor.length;

  let formattedText =
    Valor.substring(0, accountLength - 1) + "-" + Valor.substring(accountLength - 1, accountLength);
  return formattedText;
};

export const formatCEP = (value: string) => {
  const valueFormated = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

  return value.replace(valueFormated, "$1.$2-$3");
};
export const capitalizeFirstLetter = (text: string) => {
  const loweredText = text.toLowerCase();
  const words = loweredText.split(" ");
  for (let a = 0; a < words.length; a++) {
    let w = words[a];

    let firstLetter = w[0];
    w = firstLetter.toUpperCase() + w.slice(1);

    words[a] = w;
  }
  return words.join(" ");
};
