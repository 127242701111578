import React from "react";
import { TextInputContent } from "./style";

const BillingTextArea: React.FC<{
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  width?: number;
  change: (value?: string) => void;
  defaultValue?: string;
  lines: number;
  disabled?: boolean | false;
}> = ({ width, placeholder, change, defaultValue, lines, disabled }) => {
  return (
    <TextInputContent $width={width} $hasicon={false}>
      <textarea
        rows={lines}
        disabled={disabled}
        placeholder={placeholder}
        onChange={ev => {
          change(ev!.target!.value!);
        }}
        defaultValue={defaultValue}
      />
    </TextInputContent>
  );
};

export default BillingTextArea;
