import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const NovoContratoAddKitModal = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;

  .header {
    padding-bottom: 16px;
    border-bottom: 0.5px solid ${billing_theme.pallete.secundary.A100};
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;
export const NovoContratoIconWithTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid ${billing_theme.pallete.secundary.A100};
`;
export const NovoContratoInstalacaoModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const NovoContratoDescontosModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const NovoContratoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
