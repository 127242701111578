import React from "react";
import InputMask from "react-input-mask";
import { InputIcon, TextInputContent } from "./style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import billing_theme from "style/theme_colors";
import { SymbolCodepoints } from "react-material-symbols";
const BillingTextInput: React.FC<{
  placeholder?: string;
  width?: number;
  mask?: string | (string | RegExp)[];
  maskChar?: string;
  icon?: SymbolCodepoints;
  change: (value?: string) => void;
  value?: string;
  disabled?: boolean | false;
  error?: boolean;
  errorMessage?: string;
}> = ({ width, placeholder, mask, maskChar, change, icon, value, disabled }) => {
  return (
    <TextInputContent $width={width} $hasicon={icon != undefined}>
      <InputMask
        mask={mask!}
        maskChar={maskChar}
        placeholder={placeholder}
        disabled={disabled}
        onChange={ev => {
          change(ev!.target!.value!);
        }}
        defaultValue={value}
      />
      {icon && (
        <InputIcon>
          <BillingIconSharp icon={icon} color={billing_theme.pallete.primary?.A400!} />
        </InputIcon>
      )}
    </TextInputContent>
  );
};

export default BillingTextInput;
