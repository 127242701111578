import { ContratanteHttp } from "gateways/repo/contratante_http_client";
import { useState } from "react";
import { OptionsModel } from "shared/models/contratada_response.model";
import { setContratanteOptions } from "store/contratante/contratante_slice";
import { useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useContratante = () => {
  const http = new ContratanteHttp();
  const dispatch = useAppDispatch();
  const [isLoadingContratante, setLoading] = useState(false);
  const [contratanteOptions, setOption] = useState<OptionsModel[]>([]);

  const getContratatesOptions = async () => {
    setLoading(true);
    try {
      const data = await http.getListContratanteOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });

      setOption(options);
      dispatch(setContratanteOptions(options));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const formatOption = (id: number, name: string) => {
    return { value: id.toString(), label: name };
  };
  return {
    isLoadingContratante,
    contratanteOptions,
    getContratatesOptions,
  };
};
export default useContratante;
