import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModeloModel } from "shared/models/modelo_responde.model";

export interface ModeloState {
  ID?: number;
  Nome?: string;
  TipoEquipamento?: number;
  Descricao?: string;
}

const initialState: ModeloState = {};

const view_modelo_slice = createSlice({
  name: "view_modelo",
  initialState,
  reducers: {
    setModelo(state, action: PayloadAction<ModeloModel>) {
      state.Nome = action.payload.Nome;
      state.ID = action.payload.ID;
      state.Descricao = action.payload.Descricao;
      state.TipoEquipamento = action.payload.TipoEquipamento;
    },
  },
});

export const { setModelo } = view_modelo_slice.actions;
export default view_modelo_slice.reducer;
