import { BillingHttp } from "gateways/billing_http";
import { OptionsResponseModel } from "shared/models/default_models.model";
import { ModeloResponseListModel } from "shared/models/modelo_list_response.model";
import { ModeloRespondeModel } from "shared/models/modelo_responde.model";

export class ModeloHttp {
  private http = new BillingHttp();
  getListModelos = async () => {
    const reponse = await this.http.get<ModeloResponseListModel>("Modelo/List");

    return reponse.data;
  };
  getModelosOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Modelo/ListOptions");

    return reponse.data;
  };
  getModelo = async (id: number) => {
    const response = await this.http.get<ModeloRespondeModel>("Modelo/Get?id=" + id);
    return response.data;
  };
  addNewModelo = async (data: PostModeloModel) => {
    const response = await this.http.post("Modelo/Insert", { data: data });
    return response.data;
  };
  updateNewModelo = async (data: PostModeloModel) => {
    const response = await this.http.put("Modelo/Update", { data: data });
    return response.data;
  };
  deleteModelo = async (id: number | string) => {
    const response = await this.http.delete("Modelo/Delete?id=" + id);
    return response.data;
  };
}
export interface PostModeloModel {
  ID: number;
  Nome: string;
  TipoEquipamento: number;
  Descricao?: string;
}
