import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const ViewFaturaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: ${billing_theme.pallete.specific.T_50_25};
  width: 98%;
  margin: 0 auto;
  padding: 24px 0px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 0px 24px 1px rgba(190, 190, 190, 0.3803921569);
  -moz-box-shadow: 0px 0px 24px 1px rgba(190, 190, 190, 0.3803921569);
  box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  margin-top: 16px;
  margin-bottom: 16px;
`;
export const ViewFaturaInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
`;
export const ViewFaturaDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
