import styled from "styled-components";

export const NovoContratoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const NovoContratoPrazosDesconto = styled.div`
  display: grid;
  grid-template-rows: 27px 1fr;
  gap: 6px 12px;
  .prazo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
`;
