import { Heading5, Heading6 } from "components/text/heading/style";
import {
  DeleteServicoButton,
  DeleteServicoContent,
  DeleteServicoHeader,
  ServicoListErrorContent,
} from "./style";
import React from "react";
import billing_theme from "style/theme_colors";
import PrimaryButton from "components/button/billing_button";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import { Body3 } from "components/text/body/style";

const ServicoDeleteContent: React.FC<{
  onDelete: () => void;
  onClose: () => void;
  servico: string;
  onLoading: boolean;
}> = ({ onDelete, onClose, servico, onLoading }) => {
  const close = () => {
    onClose();
  };
  return (
    <ServicoListErrorContent>
      <DeleteServicoHeader>
        <BillingIconSharp icon={"delete"} color={billing_theme.pallete.error.A100} size={32} />
        <BillingIconSharp
          icon={"close"}
          color={billing_theme.pallete.primary.A400}
          size={32}
          onTap={close}
        />
      </DeleteServicoHeader>
      <DeleteServicoContent>
        <Heading5 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
          Deseja excluir o serviço: {servico}
        </Heading5>
        <Body3 color={billing_theme.pallete.tertiary.A600} fontWeight={500}>
          Ao excluir esse serviço, os dados serão movidos para a lixeira e posteriormente poderá ser
          recuperado.
        </Body3>
      </DeleteServicoContent>
      <DeleteServicoButton>
        <PrimaryButton label={"Cancelar"} onTap={() => onClose()} isSmall disable={onLoading} />
        <PrimaryButton
          label={"Deletar mesmo assim"}
          onTap={() => onDelete()}
          isSmall
          disable={onLoading}
        />
      </DeleteServicoButton>
    </ServicoListErrorContent>
  );
};

export default ServicoDeleteContent;
