import { BillingHttp } from "gateways/billing_http";
import { OptionsResponseModel } from "shared/models/default_models.model";
import { FaturaPostModel, FaturaListResponseModel } from "shared/models/fatura_list_response.model";
import { FaturaResponseModel } from "shared/models/fatura_response.model";

export class FaturaHttp {
  private http = new BillingHttp();

  getFaturaList = async () => {
    const response = await this.http.get<FaturaListResponseModel>("Fatura/List");
    return response.data;
  };

  addtNewFatura = async ({ DataRef, Contratante_ID }: FaturaPostModel) => {
    await this.http.post("Fatura/Gerar", {
      data: {
        ID: "0",
        DataRef: DataRef,
        Contratante_ID: Contratante_ID,
      },
    });
  };

  getFaturaOptions = async () => {
    const response = await this.http.get<OptionsResponseModel>("Fatura/ListOptions");
    return response.data;
  };

  deleteFatura = async (id: number) => {
    const response = await this.http.post("Fatura/Delete", {
      data: [id],
    });
    response.data;
  };

  getFaturaById = async (id: string) => {
    const response = await this.http.get<FaturaResponseModel>("Fatura/Get?id=" + id);
    return response.data;
  };
}
