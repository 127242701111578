import LineSkeleton from "components/skeleton/line_skeleton";
import {
  ModeloListButton,
  ModeloListContent,
  ModeloListHeader,
  SkeletonModeloListItensContainer,
} from "./style";
import React from "react";
const SkeletonModeloList: React.FC<{}> = () => {
  const array = ["", "", "", ""];
  return (
    <ModeloListContent>
      <ModeloListHeader>
        {array.map(() => (
          <LineSkeleton height={30} width={150} />
        ))}
      </ModeloListHeader>

      <SkeletonModeloListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonModeloListItensContainer>
      <SkeletonModeloListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonModeloListItensContainer>
      <SkeletonModeloListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonModeloListItensContainer>
      <SkeletonModeloListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonModeloListItensContainer>
      <SkeletonModeloListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonModeloListItensContainer>
      <SkeletonModeloListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonModeloListItensContainer>
    </ModeloListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <ModeloListButton>
      <LineSkeleton height={30} width={90} />
    </ModeloListButton>
  );
};

export default SkeletonModeloList;
