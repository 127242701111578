import { createSlice } from "@reduxjs/toolkit";
import { ContratadaModel, OptionsModel } from "shared/models/contratada_response.model";
import { Configuracao } from "shared/models/default_models.model";

export interface ContratoSliceType {
  list: ContratadaModel[];
  isLoadingList: boolean;
  config: Configuracao;
  options: OptionsModel[];
  loading?: boolean;
}

const initialState: ContratoSliceType = {
  list: [],
  options: [],
  isLoadingList: true,
  config: {
    TotalRegistros: 0,
    PaginaAtual: 0,
    RegistrosPorPagina: 0,
  },
};

const contratada_slice = createSlice({
  name: "contratada",
  initialState,
  reducers: {
    setContratadaList(state, action) {
      state.list = action.payload;
      state.isLoadingList = false;
    },

    setConfigList(state, action) {
      state.list = action.payload;
      state.isLoadingList = false;
    },
    setContratadaOptions(state, action) {
      state.options = action.payload;
      state.isLoadingList = false;
    },
  },
});

export const { setContratadaList, setConfigList, setContratadaOptions } = contratada_slice.actions;
export default contratada_slice.reducer;
