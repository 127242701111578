import React from "react";
import { BillingTable } from "./style";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body3 } from "components/text/body/style";
import { removeServicoState } from "store/contrato/new_contrato_slice";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import { ServicoNewContrato } from "shared/models/contrato_post.model";

const TableComponentServico: React.FC<{ servicos: ServicoNewContrato[] }> = ({ servicos }) => {
  const servico = useSelector((state: RootState) => state.servico);
  const getKitName = (id: string) => {
    return servico.options.find(el => el.value == id)?.label;
  };
  const dispatch = useAppDispatch();
  return (
    <BillingTable>
      <thead>
        <tr>
          <td>
            <Heading5 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
              Serviços
            </Heading5>
          </td>
          <td>
            <Heading5 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
              Ações
            </Heading5>
          </td>
        </tr>
      </thead>
      <tbody>
        {servicos.map(el => (
          <tr>
            <td>
              <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
                {getKitName(el.Servico_ID!.toString())}
              </Body3>
            </td>
            <td>
              <BillingIconRounded
                icon={"delete"}
                size={24}
                grade={25}
                color={billing_theme.pallete.error.A100}
                onTap={() => dispatch(removeServicoState(el))}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </BillingTable>
  );
};
export default TableComponentServico;
