import { ModeloHttp, PostModeloModel } from "gateways/repo/modelo_http_client";
import { useState } from "react";
import useModelo from "./useModelo";

const useNewModelo = () => {
  const http = new ModeloHttp();
  const [isLoadingModelo, setLoading] = useState(false);
  const [isModeloError, setError] = useState(false);
  const { getListModelos } = useModelo();

  const addNewModelo = async (data: PostModeloModel) => {
    setLoading(true);
    setError(false);
    try {
      await http.addNewModelo(data);
      getListModelos();
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    addNewModelo,
    isLoadingModelo,
    isModeloError,
  };
};
export default useNewModelo;
