import React from "react";
import { PrimaryButtonContent } from "./style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import billing_theme from "style/theme_colors";
import { SymbolCodepoints } from "react-material-symbols";

type ButtonProps = {
  leftIcon?: SymbolCodepoints;
  rigthIcon?: SymbolCodepoints;
  disable?: boolean;
  label: string;
  isFilled?: boolean;
  isSmall?: boolean;
  onTap?: () => void;
};
const PrimaryButton: React.FC<ButtonProps> = ({
  label,
  leftIcon,
  rigthIcon,
  disable = false,
  isFilled = false,
  isSmall = false,
  onTap,
}) => {
  const buttonColor = (isDisable: boolean, isFilled: boolean): string => {
    if (isDisable) return billing_theme.pallete.tertiary?.A400;
    if (isFilled) return billing_theme.pallete.primary?.A400;
    return billing_theme.light.tertiary.A25!;
  };

  const onAction = () => {
    if (!disable) onTap!();
  };
  return (
    <PrimaryButtonContent
      $isdisable={disable}
      $isfilled={isFilled}
      $issmall={isSmall}
      disabled={disable}
      onClick={() => onAction()}
    >
      <div>
        {leftIcon && (
          <BillingIconSharp icon={leftIcon} color={buttonColor(disable, isFilled)} size={20} />
        )}
        {label}
        {rigthIcon && (
          <BillingIconSharp icon={rigthIcon} color={buttonColor(disable, isFilled)} size={20} />
        )}
      </div>
    </PrimaryButtonContent>
  );
};

export default PrimaryButton;
