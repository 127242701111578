import React, { useState } from "react";
import { IndeMenuHeader, InsideMenuWrapper } from "./style";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import { SymbolCodepoints } from "react-material-symbols";

const InsideMenu: React.FC<{
  children?: React.ReactNode;
  title: string;
  icon: SymbolCodepoints;
}> = ({ children, title, icon }) => {
  const [topScroll, setTopScroll] = useState(64);

  window.addEventListener("scroll", function () {
    if (window.scrollY <= 64) {
      setTopScroll(64 - document.documentElement.scrollTop);
    } else {
      setTopScroll(0);
    }
  });
  return (
    <>
      <InsideMenuWrapper top={topScroll}>
        <IndeMenuHeader>
          <div className="icon">
            <BillingIconRounded
              icon={icon}
              size={24}
              grade={25}
              color={billing_theme.light.tertiary.A50}
            />
          </div>
          <Heading5 color={billing_theme.pallete.primary.A400} fontWeight={600}>
            {title}
          </Heading5>
        </IndeMenuHeader>
        {children}
      </InsideMenuWrapper>
      <div></div>
    </>
  );
};

export default InsideMenu;
