import styled from "styled-components";

export const NovaFaturaContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;

  .button {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }
`;

export const NovaFaturaInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
