import React, { useEffect, useState } from "react";
import { NovaFaturaContent, NovaFaturaInputLabel } from "./style";
import { Heading3, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingSelectInput from "components/input/select";
import useContratante from "hooks/useContratante";
import BillingDatePicker from "components/input/datePicker";
import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { setNovaFaturalModalState } from "store/fatura/fatura_slice";
import useNovaFatura from "hooks/useNovaFatura";
import LineSkeleton from "components/skeleton/line_skeleton";

const NovaFatura: React.FC = () => {
  const state = useSelector((state: RootState) => state.contratante);
  const { getContratatesOptions, isLoadingContratante } = useContratante();
  const { addNovaFatura, isLoadingNovaFatura } = useNovaFatura();
  const [contratanteId, setContratanteId] = useState<number>();
  const [dataRef, setDataRef] = useState<string>();
  const dispatch = useAppDispatch();

  const cancelar = () => {
    dispatch(setNovaFaturalModalState(false));
  };

  const save = () => {
    addNovaFatura(dataRef!, contratanteId!);
  };
  useEffect(() => {
    getContratatesOptions();
  }, []);

  const setValue = (value: string) => {
    setContratanteId(parseInt(value));
  };

  return (
    <NovaFaturaContent>
      <div className="header">
        <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Cadastrar nova fatura
        </Heading3>
      </div>

      <NovaFaturaInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Selecione um contratante:
        </Heading6>
        {isLoadingContratante || isLoadingNovaFatura ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            onChange={(val: any) => setValue(val?.value!)}
            options={state.options}
            placeholder={"Selecione um Contratante"}
          />
        )}
      </NovaFaturaInputLabel>

      <NovaFaturaInputLabel>
        <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
          Apartir de:
        </Heading6>
        {isLoadingContratante || isLoadingNovaFatura ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingDatePicker
            placeholder="Data da Fatura"
            onChange={date => setDataRef(date.toISOString())}
            dateFormat="MM/yyyy"
          />
        )}
      </NovaFaturaInputLabel>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => cancelar()}
          isSmall
          disable={isLoadingNovaFatura}
        />
        <PrimaryButton
          label={"Salvar"}
          disable={isLoadingContratante || isLoadingNovaFatura}
          onTap={() => save()}
          isSmall
        />
      </div>
    </NovaFaturaContent>
  );
};

export default NovaFatura;
