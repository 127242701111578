import { BillingHttp } from "gateways/billing_http";
import { NovoContrato } from "shared/models/contrato_post.model";
import { ContratoResponse, ContratoResponseModel } from "shared/models/contrato_response.model";
import { ContratoListResponseModel } from "shared/models/contratos_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";

export class ContratoHttp {
  private http = new BillingHttp();

  getListContrato = async () => {
    const reponse = await this.http.get<ContratoListResponseModel>("Contrato/List");

    return reponse.data;
  };

  getListContratoOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Contrato/ListOptions");
    return reponse.data;
  };
  addNewContrato = async (data: NovoContrato) => {
    const reponse = await this.http.post("Contrato/Insert", { data: data });
    return reponse.data;
  };
  updateContrato = async (data: ContratoResponse) => {
    const reponse = await this.http.put("Contrato/Update", { data: data });
    return reponse.data;
  };
  getContratoById = async (id: string) => {
    const reponse = await this.http.get<ContratoResponseModel>("Contrato/Get?id=" + id);
    return reponse.data;
  };

  deleteContrato = async (id: string) => {
    const reponse = await this.http.delete("Contrato/Delete?id=" + id);
    return reponse.data;
  };
}
