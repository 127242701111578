import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ViewServico } from "shared/models/view_servico_response.model";

export interface ViewServicoState {
  ID?: number;
  Nome?: string;
  Descricao?: string;
}

const initialState: ViewServicoState = {};

const view_servico = createSlice({
  name: "view_servico",
  initialState,
  reducers: {
    setServicoInfo(state, action: PayloadAction<ViewServico>) {
      state.ID = action.payload.ID;
      state.Nome = action.payload.Nome;
      state.Descricao = action.payload.Descricao;
    },

    setNameModelo(state, action: PayloadAction<string | undefined>) {
      state.Nome = action.payload;
    },

    setDescricaoModelo(state, action: PayloadAction<string | undefined>) {
      state.Descricao = action.payload;
    },
  },
});

export const { setServicoInfo, setNameModelo, setDescricaoModelo } = view_servico.actions;
export default view_servico.reducer;
