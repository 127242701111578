import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { KitModel } from "shared/models/kit_response.model";
import { Configuracao } from "shared/models/default_models.model";

export interface KitSliceType {
  list: KitModel[];
  filterListKit: KitModel[];
  config: Configuracao;
  options: OptionsModel[];
  type_kit_filter: boolean;
  type_cobranca_filter: boolean;
  type_name_filter: boolean;
  enable_filter: boolean;
  loading?: boolean;
}

const initialState: KitSliceType = {
  list: [],
  filterListKit: [],
  options: [],
  loading: false,
  config: {
    TotalRegistros: 0,
    PaginaAtual: 0,
    RegistrosPorPagina: 0,
  },
  type_kit_filter: false,
  type_cobranca_filter: false,
  type_name_filter: false,
  enable_filter: false,
};

const kit_slice = createSlice({
  name: "kit",
  initialState,
  reducers: {
    setkitList(state, action) {
      state.list = action.payload;
    },

    setConfigList(state, action) {
      state.list = action.payload;
    },
    setFilterKitList(state, action) {
      state.filterListKit = action.payload;
    },

    setkitOptions(state, action) {
      state.options = action.payload;
    },
    setTipoKitFilter(state, action: PayloadAction<boolean>) {
      state.type_kit_filter = action.payload;
    },
    setTipoCobrancaFilter(state, action: PayloadAction<boolean>) {
      state.type_cobranca_filter = action.payload;
    },
    setNameFilter(state, action: PayloadAction<boolean>) {
      state.type_name_filter = action.payload;
    },
    setFilterEnable(state, action: PayloadAction<boolean>) {
      state.enable_filter = action.payload;
    },
    setLoadingKit(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const {
  setkitList,
  setConfigList,
  setkitOptions,
  setFilterKitList,
  setFilterEnable,
  setNameFilter,
  setTipoCobrancaFilter,
  setTipoKitFilter,
  setLoadingKit,
} = kit_slice.actions;
export default kit_slice.reducer;
