import { HomeContainer } from "./style";
import React from "react";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { toastError, toastInfo, toastSuccess, toastWarning } from "components/toast/billing_toast";
const HomePage: React.FC = () => {
  return (
    <Scaffold>
      <HomeContainer>
        <PrimaryButton label={"Success"} onTap={() => toastSuccess({ title: "Contrato salvo" })} />
        <PrimaryButton label={"Error"} onTap={() => toastError({ title: "Contrato salvo" })} />
        <PrimaryButton label={"Warning"} onTap={() => toastWarning({ title: "Contrato salvo" })} />
        <PrimaryButton
          label={"Info"}
          onTap={() =>
            toastInfo({
              title: "Contrato salvo",
              message: "Parabens, você adicionou outro contrato!",
            })
          }
        />
      </HomeContainer>
    </Scaffold>
  );
};

export default HomePage;
