import { Heading4, Heading5 } from "components/text/heading/style";
import { ServicoListErrorContent } from "./style";
import React from "react";
import billing_theme from "style/theme_colors";
import PrimaryButton from "components/button/billing_button";
import BillingIconSharp from "components/billing_icon/sharp_icon";
const ServicoErrorContent: React.FC<{ retry: () => void }> = ({ retry }) => {
  return (
    <ServicoListErrorContent>
      <BillingIconSharp icon={"error"} color={billing_theme.pallete.primary.A400} size={80} />
      <div className="text">
        <Heading4 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
          Algo deu errado!
        </Heading4>
        <Heading5 color={billing_theme.pallete.tertiary.A600} fontWeight={500}>
          Parece que ouve um erro ao carregar os serviços, clique no botão abaixo para tentar
          novamente.
        </Heading5>
      </div>
      <PrimaryButton label={"Regarregar"} onTap={() => retry()} isSmall leftIcon="restart_alt" />
    </ServicoListErrorContent>
  );
};

export default ServicoErrorContent;
