import React, { useState } from "react";
import { Heading3, Heading5, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import {
  NovoContratoAddKitModal,
  NovoContratoDescontosModal,
  NovoContratoIconWithTitle,
  NovoContratoInputLabel,
  NovoContratoInstalacaoModal,
} from "./style";
import PrimaryButton from "components/button/billing_button";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import Select, { SingleValue } from "react-select";
import BillingNumberInput from "components/input/number_input";
import { RootState, useAppDispatch } from "store/store";
import { useSelector } from "react-redux";

import { addKitState } from "store/contrato/new_contrato_slice";
import { OptionsModel } from "shared/models/contratada_response.model";
import TertiaryButton from "components/button/tertiaty_button";
import { KitComplementar } from "shared/models/contrato_post.model";
const NovoContratoAddKit: React.FC<{ openModal: () => void }> = ({ openModal }) => {
  const state = useSelector((state: RootState) => state.kit);
  const dispatch = useAppDispatch();

  const [id, setId] = useState<number>();
  const [kit, setKit] = useState<string>();
  const [quantidade, setQuantidade] = useState<number>();
  const [mensalidade, setMensalidade] = useState<number>();
  const [tranferencia, setTranferencia] = useState<number>();
  const [instalacao, setInstalacao] = useState<number>();
  const [desinstalacao, setDesinstalacao] = useState<number>();

  const addKit = () => {
    const kitScope: KitComplementar = {
      Kit_ID: id!,
    };
    dispatch(addKitState(kitScope));
  };

  return (
    <NovoContratoAddKitModal>
      <div className="header">
        <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary.A100}>
          Adicionar kit
        </Heading3>
      </div>

      <NovoContratoInstalacaoModal>
        <NovoContratoIconWithTitle>
          <BillingIconSharp icon="memory" color={billing_theme.pallete.tertiary.A400} />
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Instalações
          </Heading5>
        </NovoContratoIconWithTitle>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A300}>
            Kit
          </Heading6>
          <Select
            onChange={(val: SingleValue<OptionsModel>) => {
              setId(Number(val?.value));
              setKit(val?.label!);
            }}
            options={state.options}
            placeholder={"Selecione um  Kit"}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A300}>
            Qauntidade
          </Heading6>

          <BillingNumberInput
            placeholder="Ex:10"
            width={200}
            format="decimal"
            onNumberFormat={val => {
              setQuantidade(val.number);
            }}
          />
        </NovoContratoInputLabel>
      </NovoContratoInstalacaoModal>

      <NovoContratoDescontosModal>
        <NovoContratoIconWithTitle>
          <BillingIconSharp icon="attach_money" color={billing_theme.pallete.tertiary.A400} />
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Descontos
          </Heading5>
        </NovoContratoIconWithTitle>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A300}>
            Mensalidade
          </Heading6>
          <BillingNumberInput
            placeholder="$"
            format="currency"
            currencyDisplay="symbol"
            onNumberFormat={val => {
              setMensalidade(val.number);
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A300}>
            Transferência
          </Heading6>
          <BillingNumberInput
            placeholder="$"
            format="currency"
            currencyDisplay="symbol"
            onNumberFormat={val => {
              setTranferencia(val.number);
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A300}>
            Instalação
          </Heading6>
          <BillingNumberInput
            placeholder="$"
            format="currency"
            currencyDisplay="symbol"
            onNumberFormat={val => {
              setInstalacao(val.number);
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A300}>
            Desinstalação
          </Heading6>
          <BillingNumberInput
            placeholder="$"
            format="currency"
            currencyDisplay="symbol"
            onNumberFormat={val => {
              setDesinstalacao(val.number);
            }}
          />
        </NovoContratoInputLabel>
      </NovoContratoDescontosModal>
      <div className="buttons">
        <TertiaryButton label={"Cancelar"} isSmall onTap={() => openModal()} />
        <PrimaryButton
          label={"Inserir"}
          isSmall
          onTap={() => {
            addKit();
            openModal();
          }}
        />
      </div>
    </NovoContratoAddKitModal>
  );
};
export default NovoContratoAddKit;
