import { toastError } from "components/toast/billing_toast";
import { ContratoHttp } from "gateways/repo/contrato_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NovoContrato } from "shared/models/contrato_post.model";
import { resetNovoContratoState } from "store/contrato/new_contrato_slice";

import { RootState, useAppDispatch } from "store/store";
import useBillingEnum from "./useBillingEnum";
import useContratada from "./useContratada";
import useContratante from "./useContratante";
import UseKit from "./useKit";
import useServico from "./useServico";

const useNovoContrato = () => {
  const http = new ContratoHttp();
  const { getContratadaOption } = useContratada();
  const { getContratatesOptions } = useContratante();
  const { getKitOptions } = UseKit();
  const { getServiocOptions } = useServico();
  const { getEnums } = useBillingEnum();
  const [isLoadingNovoContrato, setLoading] = useState(false);
  const [error, setListError] = useState(false);
  const state = useSelector((state: RootState) => state.novo_contrato);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadContatoData = async () => {
    setLoading(true);
    setListError(false);
    try {
      await getContratadaOption();
      await getContratatesOptions();
      await getKitOptions();
      await getServiocOptions();
      await getEnums();
    } catch (e) {
      setListError(true);
      toastError({ title: "Erro", message: "Por favor, tente novamente!" });
    } finally {
      setLoading(false);
    }
  };

  const addNewContrato = async () => {
    const data: NovoContrato = {
      ID: 0,
      Contratada_ID: Number(state.contratada),
      Contratante_ID: Number(state.contratante),
      KitPrincipal_ID: Number(state.kitPrincipal),
      Quantidade: state.quantidade,
      Mensalidade: state.mensalidade,
      Instalacao: state.instalacao,
      Desinstalacao: state.desinstalacao,
      Transferencia: state.transferencia,
      DuracaoMeses: state.duracao,
      CarenciaDias: state.carencia,
      FidelidadeMeses: state.fidelidade,
      PercentualDesconto: state.porcentagem,
      MesesDesconto: state.meses,
      Indice: state.indice,
      ValorReajustavel: state.valorReajustavel,
      ValorNaoReajustavel: state.valorNaoReajustavel,
      ProRataInicio: state.proRataInicio,
      ProRataDesinstalacao: state.proRataDesinstalacao,
      RegasInsta: [],
      KitsComplementares: state.kitsComplementares,
      Servicos: state.servico,
      Historicos: [],
    };
    setLoading(true);
    setListError(false);
    try {
      await http.addNewContrato(data);
      dispatch(resetNovoContratoState());
      navigate("/contrato");
    } catch (e) {
      console.log(e);
      setListError(true);
      toastError({ title: "Erro", message: "Por favor, tente novamente!" });
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoadingNovoContrato,
    error,
    addNewContrato,
    loadContatoData,
  };
};
export default useNovoContrato;
