import React from "react";

import { InputContent } from "./style";
import { InputNumberFormat, NumberFormatEventDetail } from "@react-input/number-format";
import { Caution1 } from "components/text/body/style";
import billing_theme from "style/theme_colors";

const BillingNumberInput: React.FC<{
  placeholder?: string;
  width?: number;
  disabled?: boolean | false;
  format?: "decimal" | "currency" | "percent" | "unit";
  currencyDisplay?: "symbol" | "narrowSymbol" | "code" | "name";
  maximumFractionDigits?: number;
  value?: string | number | readonly string[] | undefined;
  alterValue?: string | number | readonly string[] | undefined;
  onNumberFormat?: (value: NumberFormatEventDetail) => void;
  error?: boolean;
  errorMesage?: string;
}> = ({
  width,
  placeholder,
  format,
  maximumFractionDigits,
  onNumberFormat,
  currencyDisplay,
  value,
  disabled,
  alterValue,
  error,
  errorMesage,
}) => {
  return (
    <InputContent $width={width} $error={error}>
      <InputNumberFormat
        locales="pt-BR"
        currency="BRL"
        disabled={disabled}
        format={format}
        maximumFractionDigits={maximumFractionDigits}
        placeholder={placeholder}
        onNumberFormat={(event: any) => (onNumberFormat ? onNumberFormat(event.detail) : () => {})}
        currencyDisplay={currencyDisplay}
        defaultValue={value}
        value={alterValue}
      />
      {error && <Caution1 color={billing_theme.pallete.error.A100}>{errorMesage}</Caution1>}
    </InputContent>
  );
};

export default BillingNumberInput;
