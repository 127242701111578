import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const NovoContratoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const NovoContratosForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .contrantes_contratoInicial {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`;
