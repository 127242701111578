import {
  ViewKitButton,
  ViewKitContainer,
  ViewKitDetails,
  ViewKitTwoInputs,
  ViewKitValores,
} from "./style";
import { Body2 } from "components/text/body/style";
import { Heading4 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingTextInput from "components/input/text_input";
import BillingSelectInput from "components/input/select";
import ViewKitItens from "../view_kit_itens";
import { OptionsModel } from "shared/models/contratada_response.model";
import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { useNavigate } from "react-router-dom";
import {
  setKitDescrição,
  setKitModeloPrincipal,
  setKitNome,
  setKitTipoCobranca,
  setKitTipoKit,
} from "store/kit/new_kit_slice";
import BillingTextArea from "components/input/text_area";
import useViewKit from "hooks/useViewKit";
import { resetKitNew } from "store/kit/view_kit_slice";

const KitViewMain: React.FC = () => {
  const { updateKit } = useViewKit();

  const dispatch = useAppDispatch();
  const navegate = useNavigate();

  const viewKit = useSelector((state: RootState) => state.view_kit);
  const enumList = useSelector((state: RootState) => state.list_enum);

  const getOptionsModelo = () => {
    const options: OptionsModel[] = [];

    viewKit.modelo.forEach(el => {
      options.push({ value: el.ID.toString(), label: el.Text });
    });
    return options;
  };
  const modeloValue = (id: number) => {
    for (let index = 0; index < getOptionsModelo().length; index++) {
      if (getOptionsModelo()[index].value === id.toString()) {
        return getOptionsModelo()[index];
      }
    }
  };

  return (
    <ViewKitContainer>
      <Heading4 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
        Detalhe do Kit
      </Heading4>

      <ViewKitTwoInputs>
        <ViewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Nome:
          </Body2>
          <BillingTextInput
            value={viewKit.kit.Nome}
            change={value => {
              dispatch(setKitNome(value));
            }}
          />
        </ViewKitDetails>

        <ViewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Modelo Principal:
          </Body2>
          <BillingSelectInput
            placeholder="Selecione um modelo:"
            options={getOptionsModelo()}
            defaultInputValue={modeloValue(viewKit.kit.ModeloPrincipal_ID)}
            onChange={(val: any) => {
              val != undefined
                ? dispatch(setKitModeloPrincipal(Number(val.value!)))
                : dispatch(setKitModeloPrincipal(undefined));
            }}
          />
        </ViewKitDetails>
      </ViewKitTwoInputs>

      <ViewKitTwoInputs>
        <ViewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Tipo kit:
          </Body2>
          <BillingSelectInput
            placeholder="Selecione um modelo de Kit:"
            defaultInputValue={
              enumList.TipoKitOptions != undefined
                ? enumList.TipoKitOptions.find(el => el.value === viewKit.kit.TipoKit.toString())
                : []
            }
            options={enumList.TipoKitOptions!}
            onChange={(val: any) => {
              val != undefined
                ? dispatch(setKitTipoKit(Number(val.value!)))
                : dispatch(setKitTipoKit(undefined));
            }}
          />
        </ViewKitDetails>

        <ViewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Tipo de cobrança:
          </Body2>
          <BillingSelectInput
            placeholder="Selecione um modelo de cobrança:"
            defaultInputValue={
              enumList.CobrancaKitOptions
                ? enumList.CobrancaKitOptions.find(
                    el => el.value === viewKit.kit.TipoCobranca.toString()
                  )
                : []
            }
            options={enumList.CobrancaKitOptions!}
            onChange={(val: any) => {
              val != undefined
                ? dispatch(setKitTipoCobranca(Number(val.value!)))
                : dispatch(setKitTipoCobranca(undefined));
            }}
          />
        </ViewKitDetails>
      </ViewKitTwoInputs>
      <div className="descri">
        <ViewKitDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Descrição
          </Body2>
          <BillingTextArea
            defaultValue={viewKit.kit.Descricao}
            change={value => {
              dispatch(setKitDescrição(value));
            }}
            lines={3}
          />
        </ViewKitDetails>
      </div>
      <ViewKitItens />
      <ViewKitButton>
        <TertiaryButton
          label={"Voltar"}
          onTap={() => {
            navegate("/cadastro/kit");
            dispatch(resetKitNew());
          }}
          isSmall
        />
        <PrimaryButton
          label={"Editar"}
          onTap={async () => {
            await updateKit({
              ID: viewKit.kit.ID,
              Nome: viewKit.kit.Nome,
              ModeloPrincipal_ID: viewKit.kit.ModeloPrincipal_ID,
              Descricao: viewKit.kit.Descricao,
              TipoKit: viewKit.kit.TipoKit,
              TipoCobranca: viewKit.kit.TipoCobranca,
              Acessorios: viewKit.kit.Acessorios,
            });
          }}
          isSmall
        />
      </ViewKitButton>
    </ViewKitContainer>
  );
};

export default KitViewMain;
