import { BillingHttp } from "gateways/billing_http";
import { ContratanteResponseModel } from "shared/models/contratante_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";

export class ContratanteHttp {
  private http = new BillingHttp();

  getListContratante = async () => {
    const reponse = await this.http.get<ContratanteResponseModel>("Contratante/List");
    return reponse.data;
  };

  getListContratanteOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Contratante/ListOptions");
    return reponse.data;
  };
}
