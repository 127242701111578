import { BillingHttp } from "gateways/billing_http";
import { ContratadaResponseModel } from "shared/models/contratada_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";
export class ContratadaHttp {
  private http = new BillingHttp();

  getListContratada = async () => {
    const reponse = await this.http.get<ContratadaResponseModel>("Contratada/List");

    return reponse.data;
  };

  getListContratadaOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Contratada/ListOptions");
    return reponse.data;
  };
}
