import { ContratoContainer } from "./style";
import Scaffold from "components/scaffold";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";

const CadastroPage: React.FC = () => {
  return (
    <Scaffold>
      <ContratoContainer>
        <InsideMenu title={"Cadastro"} icon={"note_add"}>
          <InsideMenuButton path={"/cadastro/kit"} name={"Kit"} icon={"home_repair_service"} />
          <InsideMenuButton path={"/cadastro/servico"} name={"Serviço"} icon={"handyman"} />
          <InsideMenuButton path={"/cadastro/modelo"} name={"Modelo"} icon={"ad"} />
        </InsideMenu>
      </ContratoContainer>
    </Scaffold>
  );
};

export default CadastroPage;
