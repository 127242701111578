import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const WarningToastContainer = styled.div`
  display: flex;
  height: 72px;
  align-items: center;
  background: ${billing_theme.pallete.tertiary.A25};
  gap: 8px;
  padding: 0px 8px;
  h5 {
    line-height: 16px;
  }
`;
export const WarningIconContent = styled.div`
  background-color: ${billing_theme.pallete.warning.A100};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const WarningTextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
