import { BillingHttp } from "gateways/billing_http";
import { ListEnumResponseModel } from "shared/models/list_enum.model";

export class EnumHttp {
  private http = new BillingHttp();
  private url = process.env.REACT_APP_BILLING_DEFAUT_URL_SEM_API;
  getListEnum = async () => {
    const reponse = await this.http.get<ListEnumResponseModel>("Utility/ListAllEnum", this.url);

    return reponse.data;
  };
}
