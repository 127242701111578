import { NovoContratoInputLabel, NovoContratosForm } from "./style";
import React from "react";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingSelectInput from "components/input/select";
import { setContratadaState, setContratanteState } from "store/contrato/new_contrato_slice";

const NovoContratoContracts: React.FC = () => {
  const state = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  return (
    <NovoContratosForm>
      <div className="contrantes_contratoInicial">
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Contratantes
          </Heading6>
          <BillingSelectInput
            onChange={(val: any) => {
              val != null
                ? dispatch(setContratanteState(val?.value!))
                : dispatch(setContratanteState(""));
            }}
            options={state.contratante.options}
            placeholder={"Selecione um Contratante"}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Contratada
          </Heading6>
          <BillingSelectInput
            onChange={(val: any) => {
              val != null
                ? dispatch(setContratadaState(val?.value!))
                : dispatch(setContratadaState(""));
            }}
            options={state.contratada.options}
            placeholder={"Selecione a contratada"}
          />
        </NovoContratoInputLabel>
      </div>
    </NovoContratosForm>
  );
};
export default NovoContratoContracts;
