import React from "react";
import { InputContent } from "./style";
import Select, { GroupBase } from "react-select";
import { OptionsModel } from "shared/models/contratada_response.model";

const BillingSelectInput: React.FC<{
  placeholder?: string;
  width?: number;
  options: OptionsModel[];
  onChange: (value: any) => void;
  disabled?: boolean | false;
  defaultInputValue?: any;
  error?: boolean;
  errorMessage?: string;
}> = ({ width, placeholder, onChange, options, defaultInputValue, disabled }) => {
  return (
    <InputContent $width={width}>
      <Select
        isClearable
        isSearchable
        isDisabled={disabled}
        onChange={(val: any) => onChange(val)}
        options={options as unknown as GroupBase<string>[]}
        placeholder={placeholder}
        defaultValue={defaultInputValue as unknown as GroupBase<string>}
        className="billing"
      />
    </InputContent>
  );
};

export default BillingSelectInput;
