import React, { useState } from "react";
import { BillingTable, BillingTableContent, RowItem, TableContent } from "./style";
import { Heading4, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body3 } from "components/text/body/style";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Estoque } from "shared/models/fatura_response.model";
import { toMoney } from "utils/numberUtils";
import { toDate } from "utils/stringUtils";
import { NoContentTable } from "../../style";

const ViewFaturaEstoqueTable: React.FC = () => {
  const viewFatura = useSelector((state: RootState) => state.view_fatura);

  return (
    <TableContent>
      <Heading4 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
        Estoque
      </Heading4>
      {viewFatura.Objeto.Estoque.length == 0 && (
        <NoContentTable>
          <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
            Nenhuma item a ser mostrado!
          </Heading6>
        </NoContentTable>
      )}
      {viewFatura.Objeto.Estoque.length != 0 && (
        <BillingTableContent>
          <BillingTable>
            <thead>
              <HeadTable />
            </thead>
            <tbody>
              {viewFatura.Objeto.Estoque.map(el => (
                <ItemEstoque key={el.ID} estoque={el} />
              ))}
            </tbody>
          </BillingTable>
        </BillingTableContent>
      )}
    </TableContent>
  );
};
const HeadTable: React.FC = () => {
  return (
    <tr>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Numero de contrato
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Equipamento
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Numero de serie
        </Heading6>
      </td>

      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Mensalidade
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Descontos
        </Heading6>
      </td>
      <td>
        <Heading6 color={billing_theme.pallete.tertiary.A400} fontWeight={500}>
          Valor total
        </Heading6>
      </td>
    </tr>
  );
};
const ItemEstoque: React.FC<{ estoque: Estoque }> = ({ estoque }) => {
  return (
    <RowItem>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.NumeroContratoBilling}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.Equipamento}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {estoque.NumeroSerie}
        </Body3>
      </td>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.Mensalidade)}
        </Body3>
      </td>

      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.Descontos)}
        </Body3>
      </td>

      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {toMoney(estoque.ValorTotal)}
        </Body3>
      </td>
    </RowItem>
  );
};
export default ViewFaturaEstoqueTable;
