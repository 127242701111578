import { Heading4, Heading5 } from "components/text/heading/style";
import { FaturaListEmptyContent } from "./style";
import React from "react";
import billing_theme from "style/theme_colors";
import PrimaryButton from "components/button/billing_button";
import darkNotFound from "../../../../assets/svg/no_found_content_dark.svg";
import notFound from "../../../../assets/svg/no_found_content.svg";

const FaturaEmptyContent: React.FC<{ clear: () => void }> = ({ clear }) => {
  return (
    <FaturaListEmptyContent>
      <img src={billing_theme.isDark() ? darkNotFound : notFound} alt="" />
      <div className="text">
        <Heading4 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
          Sem resultado para sua busca!
        </Heading4>
        <Heading5 color={billing_theme.pallete.tertiary.A600} fontWeight={500}>
          Não obtivemos nenhum resultado para sua busca, tente limpar os filtros e tentar novamente.
        </Heading5>
      </div>
      <PrimaryButton label={"Limpar"} onTap={() => clear()} isSmall />
    </FaturaListEmptyContent>
  );
};

export default FaturaEmptyContent;
