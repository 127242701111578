import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const BillingHeaderContent = styled.header`
  background-color: ${billing_theme.isDark()
    ? billing_theme.light.primary?.A300
    : billing_theme.light.primary?.A400};
  height: 64px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;

  div.menu-icon {
    display: flex;
    width: 88px;
    height: 64px;
    margin: 0px 16px 0px 0px;
    justify-content: center;
    align-items: center;
    color: ${billing_theme.light.secundary.A25};
    background: transparent;
    border: none;
    border-right: solid 0.5px
      ${billing_theme.isDark()
        ? billing_theme.light.primary?.A200
        : billing_theme.light.primary?.A300};
  }

  .apps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .inputField {
    max-width: 740px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
`;

export const HeaderLogos = styled.div`
  display: flex;
  width: 100px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  flex-wrap: nowrap;

  img.billing_logo {
    width: 32px;
    border: solid 2px white;
    border-radius: 6px;
  }
`;

export const GoContent = styled.div`
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    line-height: 26px;
  }
  h6 {
    line-height: 24px;
  }
`;
