import { createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ContratanteModel } from "shared/models/contratante_response.model";
import { Configuracao } from "shared/models/default_models.model";

export interface ContratanteSliceType {
  list: ContratanteModel[];
  isLoadingList: boolean;
  config: Configuracao;
  options: OptionsModel[];
  loading?: boolean;
}

const initialState: ContratanteSliceType = {
  list: [],
  options: [],
  isLoadingList: true,
  config: {
    TotalRegistros: 0,
    PaginaAtual: 0,
    RegistrosPorPagina: 0,
  },
};

const contratante_slice = createSlice({
  name: "contratante",
  initialState,
  reducers: {
    setContratanteList(state, action) {
      state.list = action.payload;
      state.isLoadingList = false;
    },

    setConfigList(state, action) {
      state.list = action.payload;
      state.isLoadingList = false;
    },
    setContratanteOptions(state, action) {
      state.options = action.payload;
      state.isLoadingList = false;
    },
  },
});

export const { setContratanteList, setConfigList, setContratanteOptions } =
  contratante_slice.actions;
export default contratante_slice.reducer;
