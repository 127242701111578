import styled from "styled-components";

export const ScaffoldComponent = styled.main`
  display: grid;
  grid-template-columns: 89px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 0px;
`;
export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  float: left;
  width: 100%;
`;

export const ChildWrapper = styled.div`
  overflow: hidden;
  position: relative;
  float: left;
  width: 100%;
`;
