import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const BillingMenuAside = styled.aside<{
  top: number;
}>`
  display: flex;
  width: 88px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  top: ${props => props.top + "px"};
  align-content: center;
  position: fixed;
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  border-bottom: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  background: ${billing_theme.pallete.specific.T_50_25};
  z-index: 99;

  nav .menu-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  nav .menu-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .menu-item button {
    display: flex;
    height: 64px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    align-self: stretch;
    width: 88px;
    color: ${billing_theme.pallete.tertiary?.A200};
    border: none;
    border-bottom: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
    background: transparent;
  }
`;

export const MenuButton = styled.button<{
  selected: boolean;
}>`
  display: flex;
  height: 64px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  align-self: stretch;
  width: 88px;
  color: ${props =>
    props.selected ? billing_theme.pallete.primary?.A50 : billing_theme.pallete.tertiary?.A200};
  border: none;
  border-bottom: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  background: ${props => (props.selected ? billing_theme.pallete.primary?.A400 : "transparent")};
  cursor: pointer;

  .menu-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;
