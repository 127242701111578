import React from "react";
import "react-material-symbols/sharp";
import { AvatarContent } from "./style";
import billing_theme from "style/theme_colors";
import { Heading3, Heading4, Heading6 } from "components/text/heading/style";

const BillingAvatar: React.FC<{
  size: "small" | "medium" | "big";
  color: "primary" | "secundary" | "white";
  name: string;
  image?: string;
}> = ({ size, color, name }) => {
  const setBackground = () => {
    if (color == "primary") return billing_theme.pallete.primary.A400;
    if (color == "secundary") return billing_theme.pallete.secundary.A400;
    return billing_theme.pallete.tertiary.A50;
  };
  const letterColor = () => {
    return color == "white"
      ? billing_theme.pallete.primary.A400
      : billing_theme.pallete.tertiary.A50;
  };

  const Letter = () => {
    if (size == "big")
      return (
        <Heading3 color={letterColor()} fontWeight={600}>
          {avatarName()}
        </Heading3>
      );
    if (size == "medium")
      return (
        <Heading4 color={letterColor()} fontWeight={600}>
          {avatarName()}
        </Heading4>
      );
    return (
      <Heading6 color={letterColor()} fontWeight={600}>
        {avatarName()}
      </Heading6>
    );
  };
  const setSize = (): string => {
    if (size == "big") return "64px";
    if (size == "medium") return "48px";
    return "32px";
  };
  const avatarName = () => {
    if (name.split(" ").length == 1) {
      return (name[0] + name[1]).toLocaleUpperCase();
    }
    return (name.split(" ")[0][0] + name.split(" ")[1][0]).toLocaleUpperCase();
  };
  return (
    <AvatarContent $size={setSize()} $background={setBackground()}>
      <Letter />
    </AvatarContent>
  );
};

export default BillingAvatar;
