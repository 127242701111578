import React from "react";
import { BillingTable } from "./style";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body3 } from "components/text/body/style";
import { KitContratoModel } from "shared/models/kit_response.model";
import { removeKitState } from "store/contrato/new_contrato_slice";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import { KitComplementar } from "shared/models/contrato_post.model";

const TableComponentKit: React.FC<{ kits: KitComplementar[] }> = ({ kits }) => {
  const kit = useSelector((state: RootState) => state.kit);
  const dispatch = useAppDispatch();
  const getKitName = (id: string) => {
    return kit.options.find(el => el.value == id)?.label;
  };
  return (
    <BillingTable>
      <thead>
        <tr>
          <td>
            <Heading5 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
              Kit
            </Heading5>
          </td>
          <td>
            <Heading5 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
              Ação
            </Heading5>
          </td>
        </tr>
      </thead>
      <tbody>
        {kits.map(el => (
          <tr>
            <td>
              <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
                {getKitName(el.Kit_ID!.toString())}
              </Body3>
            </td>
            <td>
              <BillingIconRounded
                icon={"delete"}
                size={24}
                grade={25}
                color={billing_theme.pallete.error.A100}
                onTap={() => dispatch(removeKitState(el))}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </BillingTable>
  );
};
export default TableComponentKit;
