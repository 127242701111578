import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Lista } from "shared/models/default_models.model";
import { Acessorio } from "shared/models/kit_view_response.model";

interface KitNewState {
  kit: {
    Nome?: string;
    ModeloPrincipal_ID?: number;
    Descricao?: string;
    TipoKit?: number;
    TipoCobranca?: number;

    Acessorios?: Acessorio[];
  };
  modelo: Lista[];
  loading: boolean;
}

const initialState: KitNewState = {
  kit: {
    Acessorios: [],
  },
  modelo: [],
  loading: true,
};

const kit_new_slice = createSlice({
  name: "kitNewSlice",
  initialState,
  reducers: {
    resetKitNew: () => initialState,
    setKitModelos(state, action: PayloadAction<Lista[]>) {
      state.modelo = action.payload;
    },
    setKitNome(state, action: PayloadAction<string | undefined>) {
      state.kit.Nome = action.payload;
    },
    setKitModeloPrincipal(state, action: PayloadAction<number | undefined>) {
      state.kit.ModeloPrincipal_ID = action.payload;
    },
    setKitDescrição(state, action: PayloadAction<string | undefined>) {
      state.kit.Descricao = action.payload;
    },
    setKitTipoKit(state, action: PayloadAction<number | undefined>) {
      state.kit.TipoKit = action.payload;
    },
    setKitTipoCobranca(state, action: PayloadAction<number | undefined>) {
      state.kit.TipoCobranca = action.payload;
    },

    setKitAcessorios(state, action: PayloadAction<Acessorio[] | undefined>) {
      state.kit.Acessorios = action.payload;
    },
    setKitLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const {
  setKitModelos,
  resetKitNew,
  setKitAcessorios,
  setKitTipoCobranca,
  setKitTipoKit,
  setKitDescrição,
  setKitModeloPrincipal,
  setKitNome,
  setKitLoading,
} = kit_new_slice.actions;
export default kit_new_slice.reducer;
